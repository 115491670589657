import {StripeCreditCardForm} from './stripe_payment_form'

if document.body.id == 'payment' and document.querySelector('#interval_1')

  form = document.querySelector('.stripe-credit-card')

  selectedInterval = ->
    if document.querySelector('#interval_1').checked then 1 else 2

  paymentOptionSelected = ->
    if selectedInterval() is 1
      form.querySelector('.option1').classList.remove('hidden')
      form.querySelector('.option2').classList.add('hidden')
    else
      form.querySelector('.option1').classList.add('hidden')
      form.querySelector('.option2').classList.remove('hidden')
    cc.interval = selectedInterval()

  document.querySelector('#interval_1').addEventListener 'change', -> paymentOptionSelected()
  document.querySelector('#interval_2').addEventListener 'change', -> paymentOptionSelected()

  cc = new StripeCreditCardForm(false)

  document.querySelector('a.paypal-icon').addEventListener 'click', (e) -> 
    e.preventDefault()
    document.querySelector('.paypal-buttons').classList.add('shown')