import * as material from 'utils/material_elements'
import {element_with_class, element_with_content} from 'utils/material_elements'
import {scroll_to_element} from 'utils/scroller'
import {params_with_csrf_token} from 'utils/form_utils'
import {EmojiFallback} from 'utils/emoji_fallback'
import {MenuBuilder} from 'components/menu_builder'
import {PopupMessage} from 'components/popup_message'
import {ModifyHoldingForm} from 'generator/modify_holding_form'
import {MDCMenu} from '@material/menu'
import {Corner} from '@material/menu-surface'
import {FlashMessage} from 'components/flash'
import {MoneyFormat} from 'utils/money_format'

export class Holding

  constructor: (@parent, @portfolio, @holding) ->
    @emoji = new EmojiFallback()

  load: -> @parent.load() # called from ModifyHoldingForm

  html: ->
    @card = element_with_class('div', 'mdc-card', 'holding')
    if @portfolio.can_edit
      menu =  @buildMenu()
      @card.append element_with_content('div', menu, 'mdc-menu-surface--anchor')
      @prepareMenu(menu)
    @card.append @logo()
    @card.append @mainContent()
    @card

  logo: ->
    div = element_with_class('div', 'img')
    if @holding.img
      img = document.createElement('IMG')
      img.setAttribute('src', @holding.img)
      img.setAttribute('alt', @holding.share)
      div.append img
    div

  mainContent: ->
    div = element_with_class('div', 'center')
    div.append @titleRow()
    div.append @tickerAndFlag()
    div.append @valueBoxes()
    div.append @extraValues()
    div

  titleRow: ->
    div = element_with_class('div', 'title-with-icon')
    div.append @title()
    div.append @icons()
    div

  title: ->
    title = element_with_content('div', @holding.share, 'mdc-typography--headline5')
    title.addEventListener 'click', (e) =>  @card.classList.toggle('expanded')
    title

  icons: ->
    div = element_with_class('div', 'icons')
    div.append @menuIcon() if @portfolio.can_edit
    div.append @expandIcon()
    div

  menuIcon: ->
    icon = element_with_content('div', '&#xe5d4;', 'material-icons')
    icon.addEventListener 'click', => @menu.open = !@menu.open
    icon

  prepareMenu: (menu) ->
    @menu = new MDCMenu(menu)    
    @menu.listen 'MDCMenu:selected', (event) =>
      if event.detail.index is 0
        @showEditHolding()
      else
        @confirmRemoveHolding()
    menu

  buildMenu: ->
    builder = new MenuBuilder('Edit holding', 'Delete holding')
    builder.build()

  expandIcon: ->
    icon = element_with_content('div', '&#xe313;', 'material-icons', 'expand-icon')
    icon.addEventListener 'click', =>
      @card.classList.toggle 'expanded'
      scroll_to_element("#extra-#{@holding.id}")
    icon

  tickerAndFlag: ->
    div = element_with_class('div', 'ticker-flag')
    div.append element_with_content('span', @holding.flag)
    div.append @tickerLink()
    @emoji.replaceInElement(div)
    div

  tickerLink: ->
    link = element_with_content('a', @holding.ticker, 'ticker')
    link.setAttribute('href', @holding.share_path)
    link

  valueBoxes: ->
    div = element_with_class('div', 'horizontal-cards')
    div.append @outlayCard()
    div.append @capitalGainCard()
    div.append @dividendsCard()
    div.append @performanceCard()
    div.append @marketValueCard()
    div.addEventListener 'click', (e) =>  @card.classList.toggle('expanded')
    div

  outlayCard: ->
    quantity = "#{@holding.quantity} #{if @holding.quantity == "1" then 'share' else 'shares'}"
    outlay = @holding.outlay or @holding.toutlay
    if outlay
      @valueCard('Outlay', MoneyFormat.format(outlay, @portfolio.currency.code), quantity)
    else
      @valueCard('Holding', quantity)

  capitalGainCard: ->
    return @valueCard('Capital Gain', null) if @holdingOutlayNotSet()
    cap_gain   = if @holding.quantity == "0" then @holding.tcap_gain   else @holding.cap_gain
    cap_gain_p = if @holding.quantity == "0" then @holding.tcap_gain_p else @holding.cap_gain_p
    @valueCard('Capital Gain', MoneyFormat.format(cap_gain, @portfolio.currency.code), cap_gain_p, true)

  dividendsCard: ->
    value = if @holding.quantity == "0" then @holding.tdivs_rec else @holding.divs_rec
    return @valueCard('Dividends', MoneyFormat.format(value, @portfolio.currency.code)) if @holdingOutlayNotSet()
    percent = if @holding.quantity == "0" then @holding.tdivs_rec_p else @holding.divs_rec_p
    percent = "#{percent} yield"
    @valueCard('Dividends', MoneyFormat.format(value, @portfolio.currency.code), percent)

  performanceCard: ->
    return @valueCard('Performance', null) if @holdingOutlayNotSet()

    value   = if @holding.quantity == "0" then @holding.ttot_gain   else @holding.tot_gain
    percent = if @holding.quantity == "0" then @holding.ttot_gain_p else @holding.tot_gain_p
    @valueCard('Performance', MoneyFormat.format(value, @portfolio.currency.code), percent, true)

  marketValueCard: ->
    @valueCard('Market Value', MoneyFormat.format(@holding.cur_val, @portfolio.currency.code))

  valueCard: (label, main_value, second_value, is_percent_gain) ->
    card = element_with_class('div', 'mdc-card', 'padded-card', 'value-card')
    card.classList.add 'nil-card' if (main_value == null or main_value == undefined) and (second_value == null or second_value == undefined)
    card.append element_with_content('div', label, 'mdc-typography--overline', 'mdc-theme--secondary')
    main_value = 'n/a' if main_value == null
    card.append element_with_content('div', main_value, 'mdc-typography--headline5')

    if second_value
      value = if is_percent_gain then @percentChange(second_value, main_value[0] == '-') else element_with_content('div', second_value, 'mdc-typography--body2')
      card.append value
    card

  percentChange: (percent, negative) ->
    div = element_with_class('div', 'value')
    div.append @changeIcon(negative)
    div.append element_with_content('div', percent, 'mdc-typography--body2')
    div

  changeIcon: (negative) ->
    icon = if negative then '&#xe5db;' else '&#xe5d8;'
    arrowClass = if negative then 'down' else 'up'
    element_with_content('span', icon, 'material-icons', arrowClass)

  extraValues: ->
    div = element_with_class('div', 'extra')
    div.setAttribute('id', "extra-#{@holding.id}")
    cols = element_with_class('div', 'cols')
    cols.append @extraColumn1()
    cols.append @extraColumn2()
    div.append cols
    div.append @nextDividend() if @holding.next_div
    div

  extraColumn1: ->
    div = element_with_class('div', 'col')
    if @showCumulative()
      div.append element_with_content('div', 'Current holding', 'header', 'row', 'mdc-typography--overline', 'mdc-theme--secondary')
      div.append @detailRow('Outlay', @holding.outlay, null, true)
      div.append @detailRow('Average price', @holding.avg_price, null, true)
      div.append @detailRow('Capital gain', @holding.cap_gain, null, true)
      div.append @detailRow('Dividends', @holding.divs_rec, null, true)
      div.append @detailRow('Performance', @holding.tot_gain, null, true)
      div.append @currentPriceRow()
      div.append @detailRow('First purchased', @holding.since)
    else
      div.append @detailRow('Outlay', @holding.outlay, null, true)
      div.append @detailRow('Capital gain', @holding.cap_gain, null, true)
      div.append @detailRow('Dividends', @holding.divs_rec, null, true)
      div.append @detailRow('Performance', @holding.tot_gain, null, true)
    div

  extraColumn2: ->
    div = element_with_class('div', 'col')
    if @showCumulative()
      div.append element_with_content('div', 'Cumulative total', 'header', 'row', 'mdc-typography--overline', 'mdc-theme--secondary')
      div.append @detailRow('Outlay', @holding.toutlay, null, true)
      div.append @detailRow('Average price', @holding.tavg_price, null, true)
      div.append @detailRow('Capital gain', @holding.tcap_gain, null, true)
      div.append @detailRow('Dividends', @holding.tdivs_rec, null, true)
      div.append @detailRow('Performance', @holding.ttot_gain, null, true)
      div.append @detailRow('Sector', @holding.sector)
      div.append @detailRow('Last sold', @holding.sold)
    else
      div.append @detailRow('Purchase price', @holding.tavg_price, null, true)
      div.append @currentPriceRow()
      div.append @detailRow('Sector', @holding.sector)
      div.append @detailRow('First purchased', @holding.since)
    div

  showCumulative: -> @holding.outlay != @holding.toutlay

  detailRow: (label, val, extra, format_money) ->
    clazz = if !val then 'null' else if val[0] is "-" then 'neg' else null
    row = element_with_class('div', 'row', clazz)
    row.append element_with_content('label', label)
    if val
      val = MoneyFormat.format(val, @portfolio.currency.code) if format_money
    else
      val = 'n/a'
    row.append element_with_content('span', val)
    row.append element_with_content('span', "(#{extra})", 'hint') if extra
    row

  currentPriceRow: ->
    row = element_with_class('div', 'row')
    row.append element_with_content('label', 'Current price')
    div = document.createElement('div')
    if @holding.cap_gain and @holding.quantity != "0"
      div.append @changeIcon(@holding.cap_gain[0] == '-')
    div.append MoneyFormat.format(@holding.cur_price, @holding.cur)
    div.append(element_with_content('span', "(#{MoneyFormat.format(@holding.pr_lo, @portfolio.currency.code)})", 'fx')) unless @holding.cur == @portfolio.currency.code
    row.append div
    row

  holdingOutlayNotSet: ->
    @holding.outlay is null and @holding.toutlay is null

  nextDividend: ->
    div = element_with_class('div', 'next-dividend', 'cols')
    col1 = element_with_class('div', 'col')
    col2 = element_with_class('div', 'col')
    title = element_with_content('div', element_with_content('span', 'Next Dividend', 'mdc-typography--overline', 'mdc-theme--secondary'), 'header', 'row')
    title.append element_with_content('span', @holding.next_div.st, 'div-status')
    col1.append title
    col2.append element_with_class('div', 'header', 'row', 'header2')
    col1.append @detailRow('Ex Div Date', @holding.next_div.ex)
    col2.append @detailRow('Pay Date', @holding.next_div.pa)
    col1.append @detailRow('Per Share', @holding.next_div.am)
    payment = MoneyFormat.format(@holding.next_div.to, @holding.next_div.tc)
    payment = "#{MoneyFormat.format(@holding.next_div.lo, @portfolio.currency.code)} (#{payment})" if @holding.next_div.lo
    col2.append @detailRow('Payment', payment)
    div.append col1
    div.append col2
    div

  showEditHolding: ->
    form = new ModifyHoldingForm(@holding, @portfolio, this)
    form.load()

  confirmRemoveHolding: ->
    dialog = PopupMessage.confirm("Delete holding", "Are you sure you want to remove this holding and any associated transactions?")
    dialog.listen 'MDCDialog:closed', (event) =>
      @removeHolding() if event.detail.action == 'accept'

  removeHolding: ->
    params = params_with_csrf_token()
    params.append '_method', 'DELETE'
    fetch("/portfolios/#{@portfolio.id}/holdings/#{@holding.id}", method: 'POST', body: params, headers: {'Content-Type': 'application/x-www-form-urlencoded', 'X-Requested-With': 'XMLHttpRequest'}).then =>
      FlashMessage.success("#{@holding.share} removed successfully")
      @card.classList.add 'collapsed'
      @parent.load()