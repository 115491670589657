import {element_with_content, element_with_class} from 'utils/material_elements'
import {User} from 'utils/user'

export class Tool

  placeholderCell: ->
    td = @tableCellLink(@cellPlaceholderLabel(), @cellPlaceholderPath())
    td.classList.add 'upgrade'
    td    

  cellPlaceholderLabel: ->
    return 'Renew subscription' if User.isExpired()
    return 'Verify email to access' if User.isLoggedIn() and !User.isEmailVerified()
    return 'Upgrade to access' if User.isFreeAccount()
    'Sign up to access'

  cellPlaceholderPath: (row) ->
    return '/payments/new' if User.isExpired()
    return '#' if User.isLoggedIn() and !User.isEmailVerified()
    return '/begin-free-trial' if User.isFreeAccount()
    '/signup'

  tableCellLink: (label, path) ->
    td = @emptyCell()
    link = document.createElement('A')
    link.setAttribute('href', path)
    link.innerText = label
    td.append link
    td

  tableCell: (text) -> element_with_content('td', text, 'mdc-data-table__cell')
  emptyCell: -> element_with_class('td', 'mdc-data-table__cell')

  addEmptyRow: ->
    tr = element_with_class('tr', 'mdc-data-table__row')
    td = @tableCell('None matching filter criteria')
    td.classList.add 'none'
    td.setAttribute('colspan', 3)
    tr.append td
    @table.append tr