import * as material from 'utils/material_elements'
import {element_with_class, element_with_content} from 'utils/material_elements'
import {CookieManager} from 'utils/cookie_manager'
import {AddHoldingForm} from 'generator/add_holding_form'
import {PortfolioForm} from 'generator/portfolio_form'
import {MDCTextField} from '@material/textfield'
import {MDCTextFieldHelperText} from '@material/textfield/helper-text'
import {FlashMessage} from 'components/flash'
import {User} from 'utils/user'

export class NewPortfolioForm extends PortfolioForm

  constructor: (@parent) ->
    super(null, @parent)

  load: ->
    @emptyTab()
    @tab.append @grid()
    @scrollToContent()

  grid: ->
    div = element_with_class('div', 'mdc-layout-grid__cell--span-12')
    if User.isLoggedIn()
      if User.isExpired()
        div.append @accountExpired()
      else if User.isFreeAccount()
        div.append @freeAccount()
      else if User.isEmailVerified()
        div.append @title()
        div.append @paragraph()
        div.append @form()
      else
        div.append @emailNotVerified()
    else
      div.append @notLoggedIn()
    div

  title: ->
    element_with_content 'h2', 'New Portfolio', 'mdc-typography--headline5'

  paragraph: ->
    element_with_content 'p', "Use porfolios to group your share holdings and track their performance independently.", 'mdc-typography--subtitle1'

  form: ->
    form = element_with_class('form', 'material-form', 'portfolio-form')
    form.setAttribute("method", 'POST')
    form.setAttribute("action", "/portfolios")
    form.setAttribute('id', 'new_portfolio')
    form.append @nameInput()
    form.append @balanceInput()
    form.append @currencyInput()
    form.append @buttons()
    form

  nameInput: ->
    input = material.text_field('Portfolio name', 'portfolio[name]', @defaultPortfolioName())
    @name = new MDCTextField(input)
    element_with_content('div', input, 'input')

  defaultPortfolioName: ->
    name = document.body.dataset.userName
    if name == undefined or name.trim() == "" then "My Portfolio" else "#{name}'s Portfolio"

  balanceInput: ->
    input = material.money_input_field('Opening balance', 'portfolio[balance]', '', '£')
    @balance = new MDCTextField(input)
    div = element_with_content('div', input, 'input')
    helper = element_with_content('div', 'Optional', 'mdc-text-field-helper-text')
    div.append element_with_content('div', helper, 'mdc-text-field-helper-line')
    helperText = new MDCTextFieldHelperText(helper)
    helperText.foundation.setPersistent true
    div

  buttons: ->
    div = element_with_class('div', 'buttons')
    div.append @submitButton()
    div.append @cancelButton()
    div

  submitButton: ->
    button = material.submit_button('Create portfolio', true)
    button.addEventListener 'click', (e) => @submitClicked(e)
    button

  submitClicked: (e) ->
    e.preventDefault()
    fetch('/portfolios', method: 'POST', body: @portfolioFormParameters(), headers: {'Content-Type': 'application/x-www-form-urlencoded', 'X-Requested-With': 'XMLHttpRequest'}).then (response) => @receiveResponse(response)

  cancelButton: ->
    button = material.link_button('Cancel', '/portfolios')
    button.addEventListener 'click', (e) => @cancelClicked(e)
    button

  cancelClicked: (e) ->
    e.preventDefault()
    @parent.loadSelectedTab()

  receiveResponse: (response) ->
    response.json().then (json) => @processResponse(json)

  processResponse: (data) ->
    if data.errors
      FlashMessage.error("Unable to create portfolio")
      @showErrors(data.errors)
    else
      @id = data.id
      CookieManager.set('portfolio', @id)
      FlashMessage.success("Portfolio created successfully")
      @card = @parent.addPortfolioToList(data)
      new AddHoldingForm(@id, this, true)

  showErrors: (errors) ->
    div = document.querySelector('#form-errors')
    div.remove() if div
    div = element_with_class('div', 'error-message')
    div.setAttribute('id', 'form-errors')
    div.append element_with_content('span', msg, 'error') for msg in errors
    form = document.querySelector('form#new_portfolio')
    form.appendChild div

  # the AddHoldingForm has returned
  formComplete: ->
    @parent.portfolioClicked(@card)
