export class DateFormatter

  @formatDate: (date) ->
    return "" if date == null or date == "Invalid Date"
    "#{@twoDigits(date.getDate())} #{@monthName(date.getMonth())} #{date.getFullYear()}"

  @parseAndFormat: (str) ->
    parsed = @parseIsoDate(str)
    if parsed is null then str else @formatDate(parsed)

  @parse: (str) ->
    try
      date = @parseIsoDate(str)
      return date if date
    catch
    @parseHumanDate(str)

  @parseIsoDate: (str) ->
    vals = str.split(/\-/)
    return null if vals.length < 3
    new Date(vals[0], parseInt(vals[1])-1, vals[2])

  @parseHumanDate: (str) ->
    vals = str.split(/ /)
    return null if vals.length < 3
    new Date(vals[2], @monthNames.indexOf(vals[1]), vals[0])

  @monthName: (month) ->
    @monthNames[month]

  @monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  @twoDigits: (num) ->
    if num < 10 then "0#{num}" else num