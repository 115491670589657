import {CompanySearch} from 'components/company_search'
import {element_with_class, element_with_content} from 'utils/material_elements'
import {form_element} from 'utils/form_utils'

export class CompanySearchForm

  constructor: (@includeHoldings) ->

  form: ->
    form = form_element('#', 'GET', 'company-search')
    form.classList.add 'security-search-form'
    form.append @securitySearchInput()
    form

  prepareSearch: (callback) ->
    new CompanySearch('company-search', @includeHoldings, false, (selection) =>
      @input.value = selection.value.name
      callback(selection.value))

  securitySearchInput: ->
    el = element_with_class('label', 'mdc-text-field', 'mdc-text-field--with-trailing-icon', 'mdc-text-field--outlined')
    @input = element_with_class('input', 'mdc-text-field__input', 'degradation-placeholder')
    @input.setAttribute('aria-labelledby', 'company-search-input')
    @input.setAttribute('type', 'text')
    @input.setAttribute('name', 'search')
    @input.setAttribute('autocomplete', 'off')
    @input.setAttribute('id', 'company-search-box')
    el.append @input
    el.append element_with_content('span', 'search', 'material-icons', 'mdc-text-field__icon', 'mdc-text-field__icon--trailing')
    outline = element_with_class('div', 'mdc-notched-outline')
    outline.append element_with_class('div', 'mdc-notched-outline__leading')
    span = element_with_content('span', 'Search for a company', 'mdc-floating-label')
    span.setAttribute('id', 'company-search-input')
    outline.append element_with_content('div', span, 'mdc-notched-outline__notch')
    outline.append element_with_class('div', 'mdc-notched-outline__trailing')
    el.append outline
    el
