import {params_with_csrf_token} from './utils/form_utils'
import {element_with_content} from './utils/material_elements'
import {loadStripe} from '@stripe/stripe-js'

export class StripeCreditCardForm

  constructor: (@isUpdatingCreditCard, @callback) ->
    @interval = 1
    @form = document.querySelector('form.stripe-credit-card')

    if @form
      @prepareForm()

  prepareForm: ->
    @prepareStripe()

    @button = document.querySelector('.stripe-credit-card button.stripe-submit')
    @cancel = document.querySelector('.stripe-credit-card button.cancel-button')
    @errors = document.querySelector('.card-errors')

    @form.addEventListener  'submit', (event) => @createPaymentMethod(event)
    @button.addEventListener 'click', (event) => @createPaymentMethod(event)

  prepareStripe: ->
    key = document.body.dataset.stripePk
    loadStripe(key).then (@stripe) =>
      elements = @stripe.elements()
      @card = elements.create 'card', style: @cardElementStyle()
      @card.mount('.card-element')
      @card.addEventListener 'change', (event) => @cardDetailsChanged(event)

  cardDetailsChanged: (event) ->
    if event.error
      @setErrorMessage event.error.message
    else
      @removeErrors()

  setErrorMessage: (message) ->
    @resetForm()
    @errors.innerText = message

  removeErrors: ->
    @errors.innerText = ""

  showSuccessMessage: ->
    @form.classList.add('done')
    document.querySelector('.payment-success').classList.add('done')

  createPaymentMethod: (event) ->
    event.preventDefault()
    return if @formLoading()

    @stripe.createPaymentMethod(
      type: 'card'
      card: @card
    ).then (result) =>
      if result.error
        @setErrorMessage result.error.message
      else
        @paymentMethodCreated(result)

  loadingAnimation: ->
    el = element_with_content('div', element_with_content('div', '&#xe863;', 'material-icons', 'loading-icon', 'on'), 'loading-icon-wrap')    
    el.setAttribute('id', 'loading')
    el

  paymentMethodCreated: (result) ->
    @paymentMethod = result.paymentMethod
    @token = @paymentMethod.id
    if @isUpdatingCreditCard
      @saveCreditCard()
    else
      @createSubscription()

  saveCreditCard: ->
    params = params_with_csrf_token()
    params.append('token', @token)

    fetch('/subscriptions/credit_card', method: 'POST', body: params, headers: @headers()).then (response) => @saveCreditCardComplete(response)

  saveCreditCardComplete: (response) ->
    response.json().then (json) =>
      if json.status == 'succeeded'
        @completedSuccessfully()
      else if json.status == 'requires_action'
        @confirmCardSetup(json) # client-side 3D secure verification
      else
        @setErrorMessage json.error

  createSubscription: ->
    params = params_with_csrf_token()
    params.append('interval', @interval)
    params.append('token', @token)
    fetch('/subscriptions', method: 'POST', body: params, headers: @headers()).then (response) => @createSubscriptionComplete(response)

  confirmCardSetup: (json) ->
    @stripe.confirmCardSetup(json.client_secret, payment_method: @token).then (result) =>
      if result.error
        @setErrorMessage result.error.message
      else
        # set the default payment method
        @setDefaultPaymentMethod()
        @completedSuccessfully()

  createSubscriptionComplete: (response) ->
    response.json().then (json) =>
      if json.error
        @setErrorMessage json.error
      else if json.subscription.status == 'active'
        @completedSuccessfully()
      else
        @confirmCardPayment(json)

  confirmCardPayment: (json) ->
    client_secret = json.subscription.latest_invoice.payment_intent.client_secret
    @stripe.confirmCardPayment(client_secret, payment_method: @token).then (result) =>
      if result.error
        @setErrorMessage result.error.message
      else
        @completedSuccessfully()

  setDefaultPaymentMethod: ->
    params = params_with_csrf_token()
    params.append '_method', 'PATCH'
    params.append('token', @token)
    fetch('/subscriptions/1', method: 'POST', body: params, headers: @headers())

  completedSuccessfully: ->
    @showSuccessMessage()
    if @callback
      @callback(@paymentMethod.card)

  formLoading: ->
    return @formSubmitted if @formSubmitted
    @formSubmitted = true
    @button.parentElement.append @loadingAnimation()
    @button.classList.add 'hidden'
    @cancel.classList.add 'hidden' if @cancel
    false

  headers: ->
    {'Content-Type': 'application/x-www-form-urlencoded', 'X-Requested-With': 'XMLHttpRequest'}

  clearForm: ->
    @resetForm()
    @card.clear()

  resetForm: ->
    @formSubmitted = false
    document.getElementById('loading').remove() if document.getElementById('loading')
    @button.classList.remove('hidden')
    @cancel.classList.remove('hidden') if @cancel
    @removeErrors()

  cardElementStyle: ->
    base:
      fontSize: '16px'
      lineHeight: '24px'