import {Chart, LinearScale, BarElement, BarController, CategoryScale, Title, Tooltip} from 'chart.js'

Chart.register(LinearScale, BarElement, BarController, CategoryScale, Title, Tooltip)

columnFromTable = (table_id, column) ->
  vals = []
  vals.push(el.innerText) for el in document.querySelectorAll("table##{table_id} tbody td:nth-child(#{column})")
  vals

tableLabels = -> columnFromTable('prediction-counts', 1)
accuracyValues = -> columnFromTable('prediction-counts', 2)

buildProjectionsChart = ->
  chart = new Chart(document.querySelector('canvas#projections-graph'), {
    type: 'bar'
    data: {
      labels: tableLabels()
      datasets: [{
        label: 'Dividend Projection Accuracy'
        data: accuracyValues()
        backgroundColor: "rgba(72,193,240,1)"
        borderColor: "rgba(37,164,215,1)"
        borderWidth: 1
        fill: false
      }]
    },
    options: {
      legend:
        onClick: (e) => e.stopPropogation()
      scales: {
        yAxes: [{
          ticks: {
            callback: (value, index, values) -> "#{value}%"
          }
        }]
      }
    }
  })

buildProjectionsChart() if document.querySelector('canvas#projections-graph')