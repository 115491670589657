import {params_with_csrf_token} from 'utils/form_utils'

prepare_banner = ->
  banner = document.querySelector('.banner')
  resendEmail = document.querySelector('.banner .resend-confirmation-email')
  if resendEmail
    resendEmail.addEventListener 'click', (e) ->
      e.preventDefault()
      banner.classList.add('loading')
      resend_confirmation_email()

resend_confirmation_email = ->
  banner = document.querySelector('.banner')
  fetch('/users/send_confirmation_email', body: params_with_csrf_token(), method: 'POST', headers: {'Content-Type': 'application/x-www-form-urlencoded', 'X-Requested-With': 'XMLHttpRequest'}).then (response) ->
    banner.classList.remove('loading')
    response.json().then (json) ->
      if json.success
        close_banner()
      else
        banner.classList.add('failed')
        document.querySelector('.banner .message').innerText = json.error

export close_banner = ->
  banner = document.querySelector('.banner')
  if banner
      banner.classList.add('success', 'closed')
      setTimeout (-> banner.remove()), 400

prepare_banner()