import {MDCMenu} from '@material/menu'
import {element_with_class, element_with_content} from '../utils/material_elements'
import {params_with_csrf_token} from '../utils/form_utils'
import {User} from 'utils/user'
import {CookieManager} from '../utils/cookie_manager'
import {PopupMessage} from 'components/popup_message'
import {ModifyPortfolioForm} from 'generator/modify_portfolio_form'
import {GeneratorTab} from 'generator/generator_tab'
import {FlashMessage} from 'components/flash'
import {PercentFormat} from 'utils/percent_format'
import {MoneyFormat} from 'utils/money_format'
import {Chart, ArcElement, PieController, LinearScale, Title, Tooltip} from 'chart.js'

Chart.register(PieController, ArcElement, LinearScale, Title, Tooltip)

export class GeneratorOverview extends GeneratorTab
  
  load: ->
    fetch("/portfolios/#{@id}.json").then (response) => response.json().then (json) => @buildPage(json)

  buildPage: (@portfolio) ->
    @emptyTab()
    @addTabContent()
    @prepareMenu() if @portfolio.can_edit
    @renderChart('weighting', "Holdings", @portfolio.wl, @portfolio.wv)
    @renderChart('sectors', "Sector Weightings", @portfolio.sl, @portfolio.sv)
    @setPortfolioValue(@portfolio)

  addTabContent: ->
    @addPortfolioTitle()
    @addNumberBoxes()
    @addCashPerformance()
    @addPieCharts()

  addPortfolioTitle: ->
    div = element_with_class('div', 'mdc-layout-grid__cell--span-12')
    wrapper = element_with_content('div', @portfolioTitle(), 'title-with-icon')
    wrapper.append @menuAndIcon() if @portfolio.can_edit
    wrapper.append @hideIcon() if @shouldShowHideIcon()
    div.append wrapper
    @tab.append div

  portfolioTitle: ->
    element_with_content('h2', @portfolio.name, 'mdc-typography--headline5')

  addNumberBoxes: ->
    @tab.append @numberBox('Current Value', @portfolio.val, @portfolio.val_p)
    @tab.append @numberBox('Capital Gain', @portfolio.cap_gain, @portfolio.cap_gain_p)
    @tab.append @numberBox('Total Dividends', @portfolio.dividends, @portfolio.dividends_p)
    @tab.append @numberBox('Total Gain', @portfolio.total_gain, @portfolio.total_gain_p)

  numberBox: (label, value, percent) ->
    box = element_with_class('div', 'mdc-card', 'number-box', 'value-card')
    box.append element_with_content('div', label, 'mdc-typography--overline', 'mdc-theme--secondary')
    box.append element_with_content('div', MoneyFormat.format(value, @portfolio.currency.code), 'mdc-typography--headline5')
    box.append @numberBoxPercent(percent)
    element_with_content('div', box, 'mdc-layout-grid__cell--span-3-desktop', 'mdc-layout-grid__cell--span-4-phone', 'mdc-layout-grid__cell--span-4-tablet')

  numberBoxPercent: (percent) ->
    div = element_with_class('div', 'value')
    if percent and percent != '0'
      icon = if percent < 0 then '&#xe5db;' else '&#xe5d8;'
      class2 = if percent < 0 then 'down' else 'up'
      div.append element_with_content('span', icon, 'material-icons', class2)
      div.append element_with_content('div', "#{percent}%", 'mdc-typography--body2')
    div

  addCashPerformance: ->
    box = element_with_class('div', 'mdc-card', 'cash-performance')
    box.append element_with_content('div', 'Cash Performance', 'mdc-typography--overline', 'mdc-theme--secondary')
    box.append @performanceRow('Opening Balance', @portfolio.ob, true)
    box.append @performanceRow('Total Purchases', @portfolio.purchases)
    box.append @performanceRow('Total Sales', @portfolio.sales)
    box.append @performanceRow('Deposits', @portfolio.deposits)
    box.append @performanceRow('Withdrawals', @portfolio.withdrawals)
    box.append @performanceRow('Dividends', @portfolio.dividends)
    box.append @performanceRow('Current Balance', @portfolio.balance)
    @tab.append element_with_content('div', box, 'mdc-layout-grid__cell--span-6-desktop', 'mdc-layout-grid__cell--span-4-phone', 'mdc-layout-grid__cell--span-8-tablet')

  performanceRow: (label, value, setNowLinkIfNull) ->
    row = element_with_class('div', 'row', 'mdc-typography--body2')
    row.append element_with_content('label', label)
    span = element_with_content('span', if value? then MoneyFormat.format(value, @portfolio.currency.code) else 'Not set')
    span.append(@setBalanceButton()) if setNowLinkIfNull and !value?
    row.append span
    row

  setBalanceButton: ->
    link = element_with_content('a', 'Set now', 'set-balance-button')
    link.setAttribute('href', '#')
    link.addEventListener 'click', (e) =>
      e.preventDefault()
      @showModifyPortfolioForm()
    link

  addPieCharts: ->
    box = element_with_class('div', 'mdc-card', 'pie-charts')
    box.append @buildChartCanvas('Holdings', 'weighting')
    box.append @buildChartCanvas('Sector Weightings', 'sectors')
    @tab.append element_with_content('div', box, 'mdc-layout-grid__cell--span-6-desktop', 'mdc-layout-grid__cell--span-4-phone', 'mdc-layout-grid__cell--span-8-tablet')

  buildChartCanvas: (label, id) ->
    div = element_with_class('div', 'chart')
    div.append(element_with_content('div', label, 'mdc-typography--overline', 'mdc-theme--secondary'))
    div.append(@canvasWithId(id))
    div

  canvasWithId: (id) ->
    canvas = document.createElement('CANVAS')
    canvas.setAttribute('id', id)
    element_with_content('div', canvas, 'chart-wrapper')

  renderChart: (id, title, labels, values) ->
    colors = ['#ee1125', '#222eee', '#e210b4', '#00b5de', '#08ca5e', '#cac412', '#ca123c', '#ac6fbb', '#482f86', '#081188', '#6ab3ff', '#6af8ff', '#6affa6', '#faffbe', '#98cc68', '#5a4a1a', '#562604', '#560804','#ee1125', '#222eee', '#e210b4', '#00b5de', '#08ca5e', '#cac412', '#ca123c', '#ac6fbb', '#482f86', '#081188', '#6ab3ff', '#6af8ff', '#6affa6', '#faffbe', '#98cc68', '#5a4a1a', '#562604', '#560804','#ee1125', '#222eee', '#e210b4', '#00b5de', '#08ca5e', '#cac412', '#ca123c', '#ac6fbb', '#482f86', '#081188', '#6ab3ff', '#6af8ff', '#6affa6', '#faffbe', '#98cc68', '#5a4a1a', '#562604', '#560804']
    currency_unit = @portfolio.currency.unit
    data = {
      datasets: [{data: values, backgroundColor: colors}],
      labels: labels
    }
    options = {
      legend:
        display: false
      responsive: true
      maintainAspectRatio: false
      plugins:
        tooltip:
          enabled: false
          position: 'nearest'
          external: (context) ->
            tooltipEl = context.chart.canvas.parentNode.querySelector('.external-tooltip')
            if !tooltipEl
              tooltipEl = element_with_class('div', 'external-tooltip', 'mdc-typography--body2')
              tooltipEl.appendChild element_with_class('span', 'label')
              tooltipEl.appendChild element_with_class('span', 'value')
              context.chart.canvas.parentNode.appendChild(tooltipEl)
            tooltipItem = context.tooltip.dataPoints[0]
            if context.tooltip.opacity == 0
              tooltipEl.classList.add 'hidden'
            else
              tooltipEl.classList.remove 'hidden'
              values = tooltipItem.dataset.data
              amount = parseFloat(values[tooltipItem.dataIndex])
              amount = if amount > 10000 then "#{Math.round(amount/1000)}k" else "#{Math.round(amount)}"            
              tooltipEl.querySelector('.label').innerText = tooltipItem.label
              tooltipEl.querySelector('.value').innerText = "#{currency_unit}#{amount} (#{PercentFormat.calculatePercentage(values, tooltipItem.dataIndex)})"
    }
    new Chart(document.getElementById(id), type: 'pie', data: data, options: options)

  shouldShowHideIcon: ->
    @portfolio.id is 8 and User.isEmailVerified() and document.querySelectorAll('.portfolio-tiles .mdc-card').length > 1

  hideIcon: ->
    icon = element_with_content('div', '&#xe8f5;', 'material-icons')
    icon.addEventListener 'click', => @showConfirmHidePortfolio()
    icon

  showConfirmHidePortfolio: ->
    dialog = PopupMessage.confirm("Hide demo portfolio?", "The DividendMax Trading Portfolio is provided as a demonstration. Click Yes to remove it from your Generator view.")
    dialog.listen 'MDCDialog:closed', (event) =>
      @hideDemoPortfolio() if event.detail.action == 'accept'

  hideDemoPortfolio: ->
    params = params_with_csrf_token()
    params.append('setting', 'hide_demo_portfolio')
    params.append('value', 'true')
    fetch("/user_settings", method: 'POST', body: params, headers: {'Content-Type': 'application/x-www-form-urlencoded', 'X-Requested-With': 'XMLHttpRequest'}).then =>
      FlashMessage.success("#{@portfolio.name} Hidden Successfully")
      CookieManager.remove("portfolio")
      location.reload()

  menuAndIcon: ->
    el = element_with_class('div', 'mdc-menu-surface--anchor')
    el.append @menuIcon()
    el.append @menuElement()
    el

  menuIcon: ->
    icon = element_with_content('div', '&#xe5d4;', 'material-icons')
    icon.addEventListener 'click', => @menu.open = !@menu.open
    icon

  menuElement: ->
    element_with_content('div', @menuItems(), 'mdc-menu', 'mdc-menu-surface')

  menuItems: ->
    ul = element_with_class('ul', 'mdc-list')
    ul.setAttribute('role', 'menu')
    ul.setAttribute('aria-hidden', true)
    ul.setAttribute('aria-orientation', 'vertical')
    ul.setAttribute('tabindex', -1)
    ul.append @menuItem('Edit portfolio', 1)
    ul.append @menuItem('Delete portfolio', 2)
    ul    

  menuItem: (label, tabindex) ->
    li = element_with_content('li', element_with_content('span', label, 'mdc-list-item__text'), 'mdc-list-item')
    li.setAttribute('role', 'menuitem')
    li.setAttribute('tabindex', tabindex)
    li

  prepareMenu: ->
    @menu = new MDCMenu(document.querySelector('.mdc-menu'))
    @menu.listen 'MDCMenu:selected', (event) =>
      if event.detail.index is 0
        @showModifyPortfolioForm()
      else
        @deletePortfolioClicked()

  showModifyPortfolioForm: ->
    new ModifyPortfolioForm(@portfolio, this)

  deletePortfolioClicked: ->
    dialog = PopupMessage.confirm("Delete portfolio", "Are you sure you want to delete the portfolio #{@portfolio.name} and all transactions and holdings? This cannot be reversed")
    dialog.listen 'MDCDialog:closed', (event) =>
      @deletePortfolio() if event.detail.action == 'accept'

  deletePortfolio: ->
    params = params_with_csrf_token()
    params.append('_method', 'DELETE')
    fetch("/portfolios/#{@id}", method: 'POST', body: params, headers: {'Content-Type': 'application/x-www-form-urlencoded', 'X-Requested-With': 'XMLHttpRequest'}).then =>
      FlashMessage.success("#{@portfolio.name} Removed Successfully")
      CookieManager.remove("portfolio")
      location.reload()