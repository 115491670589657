export class Tooltips

  constructor: ->
    @prepareTooltip(el) for el in document.querySelectorAll('.floating-icon')

  prepareTooltip: (el) ->
    el.addEventListener 'click', (e) =>
      el = if e.target.classList.contains('floating-icon') then e.target else e.target.parentNode

      add = not el.classList.contains 'displayed'
      @hideAll()
      if add
        el.classList.add 'displayed'

      if @shouldAnchorRight(e)
        el.classList.add 'anchor-right'
      else
        el.classList.remove 'anchor-right'

  hideAll: ->
    el.classList.remove('displayed') for el in document.querySelectorAll('.floating-icon')

  shouldAnchorRight: (e) ->
    e.clientX > (document.body.clientWidth / 2.0)