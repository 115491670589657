import {Tool} from 'tools/tool'
import {element_with_class} from 'utils/material_elements'
import {CookieManager} from 'utils/cookie_manager'

export default class CountdownTable extends Tool

  constructor: ->
    @table = document.querySelector('body#dashboard table#countdown tbody')
    @prepare() if @table != null

  prepare: ->
    fetch(@countdownUrl(), headers: {'X-Requested-With': 'XMLHttpRequest'}).then (response) => @handleResponse(response)

  countdownUrl: ->
    country = CookieManager.get("countdown_country_value") or ""
    sector = CookieManager.get("countdown_sector_value") or ""
    index = CookieManager.get("countdown_index_value") or ""
    "/tools/countdown.json?country=#{country}&sector=#{sector}&index=#{index}"

  handleResponse: (response) ->
    response.json().then (json) =>
      @table.removeChild(@table.firstChild) while @table.firstChild
      for row, i in json.dividends
        @addRow row
        break if i > 4

      @addEmptyRow() if json.dividends.length == 0

  addRow: (row) ->
    tr = element_with_class('tr', 'mdc-data-table__row')
    tr.append @tableCell(row.x)
    tr.append @nameCell(row)
    tr.append @tableCell(row.r)
    @table.append tr

  nameCell: (row) ->
    return @tableCell(row.n) if row.n
    @placeholderCell()