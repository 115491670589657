import {MDCSelect} from '@material/select'
import {EmojiFallback} from 'utils/emoji_fallback'
import {OptimizerRow} from 'tools/optimizer_row'
import {InfiniteScroll} from 'utils/infinite_scroll'
import {Tooltips} from 'utils/tooltips'
import {CookieManager} from 'utils/cookie_manager'

export default class Optimizer

  constructor: ->
    @emoji = new EmojiFallback()
    @table = document.getElementById('dividends')
    @prepareSelectOptions()
    new Tooltips()
    @scroll = new InfiniteScroll(@queryUrl(), this, true)

  prepareSelectOptions: ->
    if !CookieManager.get("optimizer_filtered")
      CookieManager.set("optimizer_country_value", 1)
      CookieManager.set("optimizer_country_index", 1)
      CookieManager.set("optimizer_cover_value", 2)
      CookieManager.set("optimizer_cover_index", 3)
      CookieManager.set("optimizer_filtered", true)

    @durationSelect = @prepareSelect('duration')
    @sectorSelect = @prepareSelect('sector')
    @countrySelect = @prepareSelect('country')
    @marketCapSelect = @prepareSelect('market_cap')
    @coverSelect = @prepareSelect('cover')
    @cadiSelect = @prepareSelect('cadi')
    @fdiSelect = @prepareSelect('fdi')
    @indexSelect = @prepareSelect('index')

  prepareSelect: (name) ->
    select = new MDCSelect(document.querySelector("##{name}-select"))
    index = CookieManager.get("optimizer_#{name}_index")
    select.selectedIndex = parseInt(index) if index

    select.listen 'MDCSelect:change', =>
      CookieManager.set("optimizer_#{name}_index", select.selectedIndex)
      if select.selectedIndex > 0
        CookieManager.set("optimizer_#{name}_value", select.value)
      else
        CookieManager.remove("optimizer_#{name}_value")
      @runOptimizerQuery()
    select

  runOptimizerQuery: ->
    @scroll.update @queryUrl()

  dataReceived: (data) ->
    @addRow(row) for row in data
    @addEmptyRow() if data.length == 0

  addRow: (security) ->
    @table.append @securityRow(security)

  securityRow: (security) ->
    row = new OptimizerRow(security, @emoji)
    row.render()

  addEmptyRow: ->
    div = document.createElement('DIV')
    div.classList.add 'no-matches'
    div.innerText = 'Sorry, no companies match your filter criteria'
    @table.append div

  queryUrl: ->
    url = "/tools/optimizer?duration=#{@durationSelect.value}"
    url = "#{url}&country=#{@countrySelect.value}" if @countrySelect.selectedIndex > 0
    url = "#{url}&index=#{@indexSelect.value}" if @indexSelect.selectedIndex > 0
    url = "#{url}&sector=#{@sectorSelect.value}" if @sectorSelect.selectedIndex > 0
    url = "#{url}&market_cap=#{@marketCapSelect.value}" if @marketCapSelect.selectedIndex > 0
    url = "#{url}&cover=#{@coverSelect.value}" if @coverSelect.selectedIndex > 0
    url = "#{url}&cadi=#{@cadiSelect.value}" if @cadiSelect.selectedIndex > 0
    url = "#{url}&fdi=#{@fdiSelect.value}" if @fdiSelect.selectedIndex > 0
    url

new Optimizer()