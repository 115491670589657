export default class WatchlistDividendsTable

  constructor: ->
    @table = document.querySelector('table#watchlist-dividends tbody')
    @prepare() if @table != null

  prepare: ->
    fetch('/watch_lists/dividends.json', headers: {'X-Requested-With': 'XMLHttpRequest'}).then (response) => @handleResponse(response)

  handleResponse: (response) ->
    response.json().then (json) =>
      @table.removeChild(@table.firstChild) while @table.firstChild
      @addRow row for row in json.data

  addRow: (row) ->
    tr = document.createElement('TR')
    tr.classList.add('mdc-data-table__row')
    tr.append @tableCell(row.n)
    tr.append @tableCell(row.t)
    tr.append @tableCell(row.a)
    @table.append tr

  tableCell: (text) ->
    td = document.createElement('TD')
    td.classList.add('mdc-data-table__cell')
    if text
      td.innerText = text
    else
      td.append @linkToWatchlist()
    td

  linkToWatchlist: ->
    a = document.createElement('A')
    a.setAttribute('href', '/begin-free-trial')
    a.innerText = 'Upgrade'
    a