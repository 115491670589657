import {MDCSelect} from '@material/select'
import {EmojiFallback} from 'utils/emoji_fallback'
import {element_with_content, element_with_class} from 'utils/material_elements'
import {Tool} from 'tools/tool'
import {CookieManager} from 'utils/cookie_manager'

class Countdown extends Tool
  constructor: ->
    @week = 0
    @sort = 0
    @table = document.querySelector('table#countdown.tool tbody')
    if @table != null
      @prepareCountdown()

  prepareCountdown: ->
    @emoji = new EmojiFallback()
    @prepareSelectOptions()
    @prepareNavigationButtons()
    @prepareTable()
    @dateValue = document.querySelector('#date-label')
    @runCountdownQuery()
    @emoji.run()

  prepareTable: ->
    @prepareSortButton button for button in document.querySelectorAll('table#countdown a.sort')

  prepareSortButton: (button) ->
    button.addEventListener 'click', (e) =>
      e.preventDefault()
      unless button.classList.contains('active')
        b.classList.remove('active') for b in document.querySelectorAll('table#countdown a.sort')
        @sort = button.dataset.sort
        button.classList.add('active')
        @runCountdownQuery()

  prepareNavigationButtons: ->
    @prevButton = document.querySelector('.nav-buttons button.prev.week')
    @prevButton.addEventListener 'click', (e) =>
      e.preventDefault()
      if @week > -1
        @week -= 1
        @runCountdownQuery()
      @setNavigationButtonStatus()

    @nextButton = document.querySelector('.nav-buttons button.next.week')
    @nextButton.addEventListener 'click', (e) =>
      e.preventDefault()
      if @week < 12
        @week += 1
        @runCountdownQuery()
      @setNavigationButtonStatus()

  setNavigationButtonStatus: ->
    if @week <= -1
      @prevButton.setAttribute('disabled', 'disabled')
    else 
      @prevButton.removeAttribute('disabled')
    if @week > 11
      @nextButton.setAttribute('disabled', 'disabled')
    else
      @nextButton.removeAttribute('disabled')

  prepareSelectOptions: ->
    @countrySelect = @prepareSelect('country')
    @indexSelect = @prepareSelect('index')
    @sectorSelect = @prepareSelect('sector')

    @emoji.replaceInElement(document.querySelector('#country-select'))

  prepareSelect: (name) ->
    select = new MDCSelect(document.querySelector("##{name}-select"))
    index = CookieManager.get("countdown_#{name}_index")
    select.selectedIndex = parseInt(index) if index
    select.listen 'MDCSelect:change', =>
      CookieManager.set("countdown_#{name}_index", select.selectedIndex)
      if select.selectedIndex > 0
        CookieManager.set("countdown_#{name}_value", select.value)
      else
        CookieManager.remove("countdown_#{name}_value")
      @runCountdownQuery()
    select

  runCountdownQuery: ->
    fetch(@queryUrl(), headers: {'X-Requested-With': 'XMLHttpRequest'}).then (response) => @handleResponse(response)

  queryUrl: ->
    url = "/tools/countdown?week=#{@week}&sort=#{@sort}"
    url = "#{url}&country=#{@countrySelect.value}" if @countrySelect.selectedIndex > 0
    url = "#{url}&index=#{@indexSelect.value}" if @indexSelect.selectedIndex > 0
    url = "#{url}&sector=#{@sectorSelect.value}" if @sectorSelect.selectedIndex > 0
    url

  handleResponse: (response) ->
    response.json().then (json) =>
      @table.removeChild(@table.firstChild) while @table.firstChild
      @addRow(row) for row in json.dividends
      @addEmptyRow() if json.dividends.length == 0
      @dateValue.innerText = json.date

  addEmptyRow: ->
    tr = document.createElement('TR')
    tr.classList.add('mdc-data-table__row')
    td = @tableCell('None')
    td.classList.add 'none'
    td.setAttribute('colspan', 8)
    tr.append(td)
    @table.append tr

  addRow: (row) ->
    tr = document.createElement('TR')
    tr.classList.add('mdc-data-table__row')
    tr.append @nameCell(row)
    tr.append @tickerCell(row)
    tr.append @tableCell(row.p)
    tr.append @amountCell(row)
    tr.append @tableCell(row.r)
    tr.append @tableCell(row.t)
    tr.append @tableCell(row.x)
    tr.append @tableCell(row.a)
    @table.append tr

  nameCell: (row) ->
    return @tableCellLink(row.n, row.u) if row.n
    @placeholderCell()

  tickerCell: (row) ->
    @tableCell(row.k or '-')

  amountCell: (row) ->
    td = @tableCell(row.m)
    td.append element_with_content('span', 'est', 'estimate') if row.s is 3
    td

new Countdown()