export class MoneyFormat

  TEN_THOUSAND = 10000
  HUNDRED_THOUSAND = 100000
  ONE_MILLION = 1000000
  TEN_MILLION = 10000000
  HUNDRED_MILLION = 100000000

  @formatFull: (float, currency) ->
    currency or= 'GBP'
    new Intl.NumberFormat('en-US', {style: 'currency', currency: currency, minimumFractionDigits: 2, maximumFractionDigits: 2}).format(float)

  @format: (float, currency, digits) ->
    currency or= 'GBP'
    return @formatLargeAmount(float, currency) if Math.abs(float) >= HUNDRED_THOUSAND
    digits = if digits? then digits else 2
    digits = 0 if Math.abs(float) >= TEN_THOUSAND
    minDigits = if digits < 2 then digits else 2
    new Intl.NumberFormat('en-US', {style: 'currency', currency: currency, minimumFractionDigits: minDigits, maximumFractionDigits: digits}).format(float)

  @formatForInput: (float, maxDigits) ->
    return "" if float is null
    maxDigits or= 4
    new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: maxDigits, useGrouping: false}).format(float)

  @formatLargeAmount: (float, currency) ->
    if Math.abs(float) >= HUNDRED_MILLION
      val = float / ONE_MILLION
      digits = 0
      ltr = 'm'
    else if Math.abs(float) >= TEN_MILLION
      val = float / ONE_MILLION
      digits = 1
      ltr = 'm'
    else if Math.abs(float) >= ONE_MILLION
      val = float / ONE_MILLION
      digits = 2
      ltr = 'm'
    else
      val = float / 1000.0
      ltr = 'k'
      digits = 1
    f = new Intl.NumberFormat('en-US', {style: 'currency', currency: currency, minimumFractionDigits: 0, maximumFractionDigits: digits}).format(val)
    "#{f}#{ltr}"

  @formatWithSymbol: (float, symbol) ->
    amt = @roundTo(float, 2)
    amt = if Math.round(amt) == amt then "#{Math.round(amt)}.00" else if Math.round(amt * 10) == amt * 10 then "#{amt}0" else amt
    "#{symbol or '£'}#{amt}"

  @poundAmount: (float) ->
    if float > -1 and float < 0 then "-0" else Math.floor(float)

  @pennyAmount: (float) ->
    penny = Math.abs(Math.round(float * 100) - (@poundAmount(float) * 100))
    if penny < 10 then "0#{penny}" else penny

  @formatMinor: (float, currency) ->
    num = if Math.round(float) == float then Math.round(float) else float
    "#{num}#{@minorName(currency)}"

  @minorName: (currency) ->
    return 'c' if currency is 'USD'
    return 'c' if currency is 'HKD'
    return '¢' if currency is 'EUR'
    return '¢' if currency is 'CHF'
    return 'öre' if currency is 'DKK'
    return 'öre' if currency is 'NOK'
    return 'öre' if currency is 'SEK'
    'p'

  @currencySymbol: (currency) ->
    return '$' if currency is 'USD'
    return 'HK$' if currency is 'HKD'
    return '€' if currency is 'EUR'
    return 'Fr.' if currency is 'CHF'
    return 'kr.' if currency is 'DKK'
    return 'kr' if currency is 'NOK'
    return 'kr' if currency is 'SEK'
    '£'

  @toFloat: (string) ->
    parseFloat(@stripNonNumerics(string))

  @stripNonNumerics: (string) ->
    return string unless string
    string.replace(/^[a-zA-Z\.]*/, '').replace(/[^0-9\.]/g, "")

  @roundTo: (num, dp) ->
    mult = 10 ** dp
    Math.round(num * mult) / mult