import * as material from 'utils/material_elements'
import {element_with_class, element_with_content} from 'utils/material_elements'
import {Holding} from 'generator/holding'
import {GeneratorTab} from 'generator/generator_tab'
import {AddHoldingForm} from 'generator/add_holding_form'
import {MoneyFormat} from 'utils/money_format'

export class Holdings extends GeneratorTab
  
  load: ->
    fetch("/portfolios/#{@id}/holdings.json").then (response) => @receiveHoldings(response)

  formComplete: ->
    @reload()
    @scrollToContent()

  receiveHoldings: (response) ->
    response.json().then (@portfolio) =>
      @emptyTab()
      @buildTabContent()
      @setPortfolioValue(@portfolio)

  buildTabContent: ->
    grid = element_with_class('div', 'mdc-layout-grid__cell--span-12')
    grid.append @top()
    grid.append @holdingsList()
    @tab.append grid

  top: ->
    div = element_with_class('div', 'title-and-status')
    div.append @portfolioValueCard()
    div.append @addButton() if @portfolio.can_edit
    div

  portfolioValueCard: ->
    card = element_with_class('div', 'mdc-card', 'padded-card', 'value-card')
    card.append element_with_content('div', 'Market Value', 'mdc-typography--overline', 'mdc-theme--secondary')
    text = if @portfolio.mkt_val == null then "#{@portfolio.currency.unit}0" else MoneyFormat.format(@portfolio.mkt_val, @portfolio.currency.code)
    card.append element_with_content('div', text, 'mdc-typography--headline5', 'portfolio-value')
    text = if @portfolio.cur is 1 then "1 holding" else "#{@portfolio.cur} holdings"
    card.append element_with_content('div', text, 'mdc-typography--body2', 'portfolio-holdings')
    card

  holdingsList: ->
    @holdings = element_with_class('div', 'holding-list')
    @addHoldings()
    @holdings

  addButton: ->
    @button = material.submit_button 'Add Holding', true
    @button.addEventListener 'click', => @addHoldingClicked()
    @button

  addHoldings: ->
    @addHolding(holding) for holding in @portfolio.holdings
    @addEmptyMessage() if @portfolio.holdings.length == 0

  addHolding: (holding) ->
    h = new Holding(this, @portfolio, holding)
    @holdings.append h.html()

  addHoldingClicked: ->
    new AddHoldingForm(@id, this, false)

  addEmptyMessage: ->
    @holdings.append element_with_content('div', 'This portfolio is empty', 'notice')