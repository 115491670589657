import {MDCMenu} from '@material/menu'
import {EmojiFallback} from 'utils/emoji_fallback'

class CompanyCoverageTable

  constructor: ->
    @selectedSector = null
    @selectedCountry = null
    @prepareFilterMenus()
    @emoji = new EmojiFallback()
    @emoji.run()

  prepareFilterMenus: ->
    @sectorsMenuLink = document.querySelector('#open-sector-filter-menu')
    @countriesMenuLink = document.querySelector('#open-country-filter-menu')
      
    @countriesMenuLink.classList.add('active') if @selectedCountry
    @sectorsMenuLink.classList.add('active') if @selectedSector

    @sectorsMenu = new MDCMenu(document.querySelector('#sector-menu'))
    @sectorsMenu.setFixedPosition true
    @sectorsMenu.listen 'MDCMenu:selected', (event) =>
      @selectedSector = event.detail.item.dataset.id or null
      @applyFilters()

    @countriesMenu = new MDCMenu(document.querySelector('#country-menu'))
    @countriesMenu.setFixedPosition true
    @countriesMenu.listen 'MDCMenu:selected', (event) =>
      @selectedCountry = event.detail.item.dataset.id or null
      @applyFilters()

    @countriesMenuLink.addEventListener 'click', (e) => 
      e.preventDefault()
      @countriesMenu.open = true

    @sectorsMenuLink.addEventListener 'click', (e) => 
      e.preventDefault()
      @sectorsMenu.open = true

  applyFilters: ->
    fetch(@path(), @headers()).then (source) => (source.text()).then (text) => 
      @setTableContent(text)
      @prepareFilterMenus()
      @emoji.run()

  setTableContent: (source) ->
    document.querySelector('#c').innerHTML = source

  headers: ->
    headers:
      'X-Requested-With': 'XMLHttpRequest'

  path: ->
    "/company-dividend-coverage?country=#{@selectedCountry}&sector=#{@selectedSector}"

new CompanyCoverageTable()