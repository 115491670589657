import {Tool} from 'tools/tool'
import {CookieManager} from 'utils/cookie_manager'
import {element_with_class} from 'utils/material_elements'

export default class OptimizerTable extends Tool

  constructor: ->
    @table = document.querySelector('table#optimizer tbody')
    @prepare() if @table != null

  prepare: ->
    fetch(@optimizerUrl(), headers: {'X-Requested-With': 'XMLHttpRequest'}).then (response) => @handleResponse(response)

  optimizerUrl: ->
    if CookieManager.get("optimizer_filtered")
      market_cap = CookieManager.get("optimizer_market_cap_value") or ""
      cover = CookieManager.get("optimizer_cover_value") or ""
      cadi = CookieManager.get("optimizer_cadi_value") or ""
      fdi = CookieManager.get("optimizer_fdi_value") or ""
      country = CookieManager.get("optimizer_country_value") or ""
      return "/tools/optimizer.json?duration=3d&market_cap=#{market_cap}&cover=#{cover}&cadi=#{cadi}&fdi=#{fdi}&country=#{country}&page=1"
    "/tools/optimizer.json?duration=3d&cover=2&country=1&page=1"

  handleResponse: (response) ->
    response.json().then (json) =>
      @table.removeChild(@table.firstChild) while @table.firstChild
      @addRow row for row in json.data
      @addEmptyRow() if json.data.length == 0

  addRow: (row) ->
    tr = document.createElement('TR')
    tr.classList.add('mdc-data-table__row')
    tr.append @tableCell(row.ann_yield)
    tr.append @nameCell(row)
    tr.append @tableCell(row.divs[0].ex_date)
    @table.append tr

  nameCell: (row) ->
    return @tableCell(row.name) if row.name
    @placeholderCell()