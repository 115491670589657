import * as material from 'utils/material_elements'
import {element_with_class, element_with_content} from 'utils/material_elements'
import {MDCTabBar} from '@material/tab-bar'
import {User} from 'utils/user'
import {PopupMessage} from 'components/popup_message'
import {GeneratorOverview} from 'generator/overview'
import {CookieManager} from 'utils/cookie_manager'
import {NewPortfolioForm} from 'generator/new_portfolio_form'
import {Holdings} from 'generator/holdings'
import {FutureIncome} from 'generator/future_income'
import {Transactions} from 'generator/transactions'
import {MoneyFormat} from 'utils/money_format'

class Generator

  constructor: ->
    @id = document.querySelector('#portfolio-data').dataset.portfolio
    @activeTab = @whatIsActiveTab()
    @prepareButtons()
    @prepareTabs()
    @preparePortfolios()

  whatIsActiveTab: ->
    switch document.location.hash
      when '#holdings' then 1
      when '#income' then 2
      when '#transactions' then 3
      else 0

  prepareButtons: ->
    document.querySelector('.add-portfolio').addEventListener 'click', => @addPortfolioButtonClicked()

  addPortfolioButtonClicked: ->
    new NewPortfolioForm(this)

  preparePortfolios: ->
    @preparePortfolio(portfolio) for portfolio in document.querySelectorAll('.portfolio-tiles .mdc-card')    

  preparePortfolio: (portfolio) ->
    portfolio.addEventListener 'click', (e) => @portfolioClicked(portfolio)

  portfolioClicked: (portfolio) ->
    if @canAccessPortfolios()
      @setPortfolioActive(portfolio)
      CookieManager.set('portfolio', @id)
      @loadSelectedTab()
    else
      @showRestrictionPopup()

  canAccessPortfolios: -> User.isEmailVerified() and !User.isExpired()

  showRestrictionPopup: ->
    message = "You need to click the link in the verification email we sent you to access the Generator"
    message = "Your subscription has expired. You need to pay for DividendMax to continue using the Generator" if User.isExpired()
    PopupMessage.alert(message)

  addPortfolioToList: (data) ->
    card = @portfolioCard(data)
    document.querySelector('.portfolio-tiles').prepend(card)
    @preparePortfolio(card)
    @setPortfolioActive(card)
    card

  portfolioCard: (data) ->
    card = material.element_with_class('div', 'mdc-card', 'mdc-elevation--z10', 'value-card', "portfolio-#{data.id}")
    card.dataset.id = data.id
    inner = element_with_class('div', 'mdc-card__primary-action')
    inner.append element_with_content('div', data.name, 'mdc-typography--body2', 'name')
    inner.append element_with_content('div', MoneyFormat.format(data.ob), 'mdc-typography--headline5', 'mv')
    value = element_with_class('div', 'value')
    value.append element_with_class('span', 'material-icons')
    value.append element_with_class('div', 'mdc-typography--body2')
    inner.append value
    card.append inner
    card

  setPortfolioActive: (portfolio) ->
    @id = portfolio.dataset.id
    p.classList.remove('active') for p in document.querySelectorAll('.portfolio-tiles .mdc-card')
    portfolio.classList.add 'active'

  prepareTabs: ->
    tabBar = new MDCTabBar(document.querySelector('.tab-tab-tab .mdc-tab-bar'))
    tabBar.listen 'MDCTabBar:activated', (event) => @generatorTabClicked(event.detail.index)
    tabBar.activateTab @activeTab
    @loadTab()

  generatorTabClicked: (tab) ->
    if @activeTab != tab
      @activeTab = tab
      @loadSelectedTab()

  loadTab: ->
    if document.location.hash is '#new'
      @loadNewPortfolio()
    else
      @loadSelectedTab()

  loadSelectedTab: ->
    switch @activeTab
      when 0 then @loadOverview()
      when 1 then @loadHoldings()
      when 2 then @loadFutureIncome()
      when 3 then @loadTransactions()

  loadOverview: ->
    @navigateTo '#'
    new GeneratorOverview(@id, this)

  loadHoldings: ->
    @navigateTo '#holdings'
    new Holdings(@id, this)

  loadFutureIncome: ->
    @navigateTo '#income'
    new FutureIncome(@id, this)

  loadTransactions: ->
    @navigateTo '#transactions'
    new Transactions(@id, this, @currencies)

  loadNewPortfolio: ->
    new NewPortfolioForm(this)

  navigateTo: (hash) ->
    if history.pushState
      history.pushState(null, null, hash)
    else
      location.hash = hash

if document.body.id == 'portfolios'
  new Generator()