import {element_with_class, element_with_content, submit_button, text_field, money_input_field, integer_input_field} from 'utils/material_elements'
import {DateFormatter} from 'utils/date_formatter'
import {GeneratorTab} from 'generator/generator_tab'
import {MDCTextField} from '@material/textfield'
import {MDCTextFieldHelperText} from '@material/textfield/helper-text'
import {MoneyFormat} from 'utils/money_format'
import {params_with_csrf_token} from 'utils/form_utils'
import DatePicker from 'tui-date-picker'

export class TransactionForm extends GeneratorTab

  transactionDateInput: ->
    element_with_content('div', @transactionDateField(), 'buy', 'sell', 'deposit', 'withdraw', 'input', 'date')

  transactionDateField: ->
    field = @textInput("Transaction Date", 'transaction[transaction_date]', 'transaction_transaction_date', DateFormatter.formatDate(new Date()))
    @transactionDate = field.querySelector('input')
    @transactionDate.setAttribute('readonly', true)
    field

  prepareDatePicker: ->
    container = element_with_class('div', 'date-picker-container')
    @transactionDate.parentElement.parentElement.append container
    date = if @transactionDate.value.length > 0 then DateFormatter.parse(@transactionDate.value) else new Date()

    picker = new DatePicker container,
      date: date
      usageStatistics: false
      calendar:
        showToday: true
      input:
        element: @transactionDate
        format: 'dd MMMM yyyy'

    picker.on 'change', => 
      @loadExchangeRate()

  quantityInput: -> 
    field = text_field('Quantity', 'transaction[quantity]', if @transaction then @transaction.qty else "")
    input = field.querySelector('input')
    input.setAttribute('id', 'transaction_quantity')
    input.setAttribute('pattern', "[0-9\\.]*")
    input.setAttribute('inputmode', "numeric")
    input.setAttribute('aria-controls', "quantity-helper")
    input.setAttribute('aria-describedby', "quantity-helper")
    input.setAttribute('min', 1)
    input.setAttribute('max', 2147483647)
    @quantity = new MDCTextField(field)
    @quantity.useNativeValidation = false
    input.addEventListener 'keyup', =>
      input.value = input.value.replace(/[^0-9]/g, "")
      valid = input.value.length == 0 or parseInt(input.value) <= MAX_INTEGER
      @quantity.valid = valid
      @quantityError.foundation.setPersistent !valid
      @updateTotal() if valid
    element = element_with_content('div', field, 'input', 'with-validation', 'quantity')
    helper = @inputHelperText('quantity-helper', 'Quantity is too big')
    @quantityError = new MDCTextFieldHelperText(helper.querySelector('.mdc-text-field-helper-text'))
    element.append helper
    element

  priceAndCurrencyInput: (label) -> 
    label or= 'Purchase Price'
    div = element_with_content('div', @priceInput(label), 'line')
    div.append @currencyInput() if @type is 1 or @type is 2
    div

  priceInput: (label) -> @moneyInputField(label, 'transaction[price]', 'transaction_price', 'price', 'with-validation')

  exchangeRateInput: ->
    field = @numberInputField('Exchange rate', 'transaction[exchange_rate]', 'transaction_exchange_rate', 'exchange-rate', 'hidden')
    field.classList.remove('hidden') if @transaction and @transaction.curr and @transaction.curr.id != @portfolioCurrency.id
    @exchangeRate = field.querySelector('input')
    field

  commissionInput: -> @moneyInputField('Commission', 'transaction[commission]', 'transaction_commission', 'commission', 'with-validation')
  stampDutyInput: -> @moneyInputField('Stamp duty', 'transaction[stamp_duty]', 'transaction_stamp_duty', 'stamp_duty', 'with-validation')

  numberInputField: (label, name, id, classes...) ->
    input = @numberInput(label, name, id)
    input.setAttribute 'pattern', "[0-9\\.]*"
    input.setAttribute 'inputmode', "numeric"
    element_with_content('div', input, 'input', classes...)

  numberInput: (label, name, id, value) ->
    field = text_field(label, name, value or null)
    input = field.querySelector('input')
    input.setAttribute('id', id)
    input.setAttribute 'pattern', "[0-9\\.]*"
    input.setAttribute 'inputmode', "numeric"    
    input.addEventListener('keyup', => @updateTotal(input))
    new MDCTextField(field)
    field

  textInput: (label, name, id, value) ->
    field = text_field(label, name, value or null)
    input = field.querySelector('input')
    input.setAttribute('id', id)
    new MDCTextField(field)
    field

  moneyInputField: (label, name, id, classes...) -> 
    field = element_with_content('div', money_input_field(label, name, null), 'input', classes...)
    textfield = new MDCTextField(field.querySelector('label'))
    helper = @inputHelperText("#{id}-helper", "#{label} is too big")
    error = new MDCTextFieldHelperText(helper.querySelector('.mdc-text-field-helper-text'))
    field.append helper
    input = field.querySelector('input')
    input.setAttribute('id', id)
    input.setAttribute('aria-controls', "#{id}-helper")
    input.setAttribute('aria-describedby', "#{id}-helper")    
    input.addEventListener 'keyup', =>
      input.value = input.value.replace(/[^0-9\.]/g, "")
      valid = input.value.length == 0 or parseFloat(input.value) <= MAX_PRICE
      textfield.valid = valid
      error.foundation.setPersistent !valid
      @updateTotal(input) if valid
    field

  inputHelperText: (id, message) ->
    text = element_with_content('div', message, 'mdc-text-field-helper-text', 'mdc-text-field-helper-text--validation-msg')
    text.setAttribute('id', id)
    text.setAttribute('aria-hidden', true)
    text.setAttribute('role', 'alert')
    element_with_content('div', text, 'mdc-text-field-helper-line')

  emptySelect: (name, id) ->
    select = document.createElement('SELECT')
    select.setAttribute('name', name)
    select.setAttribute('id', id)
    select

  totalBox: ->
    box = element_with_class('div', 'mdc-card', 'padded-card', 'value-card')
    box.append element_with_content('div', 'Total', 'mdc-typography--overline', 'mdc-theme--secondary')
    @total = element_with_content('div', '£0.00', 'mdc-typography--headline5')
    box.append @total
    box

  formButtons: ->
    div = element_with_class('div', 'buttons')
    @submitButton = submit_button(@submitButtonTitle(), true)
    div.append @submitButton
    div.append @cancelButton()
    div

  cancelButton: ->
    button = submit_button('Cancel')
    button.addEventListener 'click', (e) =>
      e.preventDefault()
      @parent.reload()
      @scrollToContent()      
    button

  submitButtonTitle: -> "Add Transaction"

  resetForm: ->
    $('#transaction_commission').val('')
    $('#transaction_stamp_duty').val('')
    $('#transaction_exchange_rate').val('1')
    $('#transaction_stamp_duty').data('set', null)

  priceInputLabel: ->
    switch @type
      when 1 then 'Purchase price'
      when 2 then 'Sale price'
      else "Amount (#{@portfolioCurrency.unit})"

  currencySelected: ->
    if @selectedCurrencyField('code') == @portfolioCurrency.code
      @exchangeRate.value = 1
      document.querySelector('.exchange-rate').classList.add('hidden')
    else
      document.querySelector('.exchange-rate').classList.remove('hidden')
      @loadExchangeRate()

    @updateCurrencySymbols()
    @updateTotal()

  updateCurrencySymbols: ->
    symbol = @selectedCurrencyField('unit')
    el.innerText = symbol for el in document.querySelectorAll('.currency-symbol')

  selectedCurrencyField: (field) ->
    return @transactionCurrencyField(field) if @transactionsNotLoaded()
    super(field)

  transactionsNotLoaded: -> @currency_select is undefined or @currency_select.querySelectorAll('li').length is 0

  transactionCurrencyField: (field) ->
    if @transaction and @transaction.curr then @transaction.curr[field] else @portfolioCurrency[field]

  loadExchangeRate: ->
    code = @selectedCurrencyField('code')
    unless code == @portfolioCurrency.code or @exchangeRate.dataset.set
      url = "/exchange_rates/#{code}?source=#{@portfolioCurrency.code}&date=#{@transactionDate.value}"
      fetch(url).then (response) => response.json().then (json) => @setExchangeRate(json)

  setExchangeRate: (json) ->
    rate = Math.round((1 / json.rate) * 10000) / 10000
    field = document.querySelector('.exchange-rate')
    @setFieldValue(field, rate)
    field.classList.add('shown')
    @updateTotal()

  setFieldValue: (field, value) ->
    return unless field
    input = field.querySelector('input')
    unless input.dataset.set
      input.value = value
      field.querySelector('label').classList.add('mdc-text-field--label-floating')
      field.querySelector('label .mdc-floating-label').classList.add('mdc-floating-label--float-above')

  updateTotal: (input) ->
    input.dataset.set = true if input
    if @type is 1 or @type is 2
      quantity = parseInt(document.querySelector('#transaction_quantity').value)
      if isNaN(quantity)
        @total.innerText = MoneyFormat.formatFull(0, @selectedCurrencyField('code'))
      else
        price = @parseFloatValueOf('#transaction_price')
        @updateStampDuty(price, quantity) if @type is 1
        commission = @parseFloatValueOf('#transaction_commission')
        stamp_duty =  if @type is 1 then @parseFloatValueOf('#transaction_stamp_duty') else 0
        amount = if @type is 1 then quantity * price + commission + stamp_duty else quantity * price - commission
        @total.innerText = MoneyFormat.formatFull(amount, @selectedCurrencyField('code'))
    else
      @total.innerText = MoneyFormat.formatFull(@parseFloatValueOf('#transaction_price'), @portfolioCurrency.code)

  validateCommonFormFields: ->
    errors = []
    if @type is 1 or @type is 2
      @validateIntegerValue('Quantity', errors, true)
      @validateIntegerValue('Commission', errors, false, true)
    if @type is 1
      @validateIntegerValue('Stamp Duty', errors, false, true)
    @validatePrice(errors)
    errors

  MAX_INTEGER = 2147483647
  MAX_PRICE   = 99999999

  validateIntegerValue: (name, errors, required, money_amount) ->
    id = "#transaction_#{name.toLowerCase().replace(" ", "_")}"
    val = parseInt(document.querySelector(id).value)
    errors.push("#{name} is required") if required and (val == 0 or isNaN(val))
    max = if money_amount then MAX_INTEGER / 100.0 else MAX_INTEGER
    display_max = if money_amount then '21,474,836' else '2,147,483,647'
    errors.push("#{name} is too large. Maximum allowed value is #{display_max}") if val > max

  validatePrice: (errors) ->
    val = parseFloat(document.querySelector('#transaction_price').value)
    if val < 0 or isNaN(val)
      errors.push("Price is not valid")
    else if val > MAX_PRICE
      errors.push("Price is too large. Maximum allowed value is 99,999,999")

  parseFloatValueOf: (id) ->
    val = parseFloat(document.querySelector(id).value)
    if isNaN(val) then 0 else val

  updateStampDuty: (price, quantity) ->
    return unless @share
    value = if @share and @share.sd then Math.round(0.005 * price * quantity) else 0
    @setFieldValue document.querySelector('.stamp_duty'), value