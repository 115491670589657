import * as material from 'utils/material_elements'
import {element_with_class, element_with_content} from 'utils/material_elements'
import {params_with_csrf_token} from 'utils/form_utils'
import {scroll_to_element} from 'utils/scroller'
import {MDCTextField} from '@material/textfield'
import {MDCList} from '@material/list'
import {MDCMenu} from '@material/menu'
import {GeneratorTab} from 'generator/generator_tab'
import {SelectedSecuritiesList} from 'components/selected_securities_list'
import {SecurityListItem} from 'components/security_list_item'
import {PopupMessage} from 'components/popup_message'
import {InfiniteScroll} from 'utils/infinite_scroll'
import {MenuBuilder} from 'components/menu_builder'
import {CompanySearchForm} from 'components/company_search_form'

export class AddHoldingForm extends GeneratorTab
  
  constructor: (@id, @parent, @addingToNewPortfolio) ->
    super(@id, @parent)

  load: ->
    @emptyTab()
    @buildTabContent()
    @selectedList = new SelectedSecuritiesList('.selected-securities')    
    @downloadHoldings()
    @search.prepareSearch((value) => @addSelectedSearchResult(value))

  downloadHoldings: ->
    if @addingToNewPortfolio
      @existing = []
    else
      fetch("portfolios/#{@id}/holdings.json").then (response) => @receiveHoldings(response)

  receiveHoldings: (response) ->
    response.json().then (json) =>
      @existing = json.holdings.map (holding) -> holding.share_id

  addSecurities: (securities) ->
    @addSecurity(security) for security in securities

  addSecurity: (security) ->
    @popularList.root.append(@securityListItem(security))    

  securityListItem: (security) ->
    item = new SecurityListItem(security)
    item.html()

  buildTabContent: ->
    @appendTitleCell()
    @appendSelectedListCell()
    @appendSecuritiesListCell()
    new InfiniteScroll("/shares.json?portfolio=#{@id}", this)

  appendTitleCell: ->
    div = element_with_content('div', @title(), 'mdc-layout-grid__cell--span-12')
    div.append @paragraph()
    @tab.append div

  title: ->
    element_with_content 'h2', 'New Portfolio', 'mdc-typography--headline5'

  paragraph: ->
    element_with_content 'p', "Select the companies to add to your portfolio.", 'mdc-typography--subtitle1'

  appendSelectedListCell: ->
    @destination = element_with_class('div', 'mdc-layout-grid__cell--span-4-phone', 'mdc-layout-grid__cell--span-4-tablet', 'mdc-layout-grid__cell--span-6-desktop', 'destination-list')
    @destination.append @selectedSecurityMenu()
    @destination.append element_with_content('div', 'Holdings', 'mdc-typography--overline', 'mdc-theme--secondary')
    empty = element_with_content('div', 'Choose a company to add to your portfolio', 'empty')
    @destination.append element_with_content('ul', empty, 'mdc-list', 'mdc-list--two-line', 'mdc-list--avatar-list', 'selected-securities', 'selected-security-list')
    @destination.append @buttons()
    @tab.append @destination

  buttons: ->
    div = element_with_class('div', 'buttons')
    div.append @submitButton()
    div.append @cancelButton()
    div

  submitButton: ->
    @button = material.submit_button('Add To Portfolio', true)
    @button.setAttribute('disabled', true)
    @button.addEventListener 'click', (e) => @submitButtonClicked()
    @button

  cancelButton: ->
    label = if @addingToNewPortfolio then 'Skip this step' else 'Cancel'
    button = material.submit_button(label)
    button.addEventListener 'click', (e) => @parent.formComplete()
    button

  appendSecuritiesListCell: ->
    div = element_with_class('div', 'mdc-layout-grid__cell--span-4-phone', 'mdc-layout-grid__cell--span-4-tablet', 'mdc-layout-grid__cell--span-6-desktop')
    @tab.append div
    div.append element_with_content('div', 'Select companies to add', 'mdc-typography--overline', 'mdc-theme--secondary')
    div.append @securitySearchForm()
    @appendPopularSecuritiesList(div)

  securitySearchForm: ->
    @search = new CompanySearchForm(false)
    @search.form()

  addSelectedSearchResult: (row) ->
    @securitySelected(row)
    document.querySelector('#company-search-box').value = ''

  appendPopularSecuritiesList: (div) ->
    ul = element_with_class('ul', 'mdc-list', 'mdc-list--avatar-list', 'source-securities-list', 'infinite-scroll-container')
    div.append ul
    @popularList = new MDCList(ul)
    @popularList.listen 'MDCList:action', (action) =>
      @securitySelected @popularList.listElements[action.detail.index].dataset

  dataReceived: (securities) ->
    @addSecurities securities

  securitySelected: (security) ->
    return if @checkNotExistingHolding(security)

    @destination.classList.add 'shown'
    row = document.querySelector(".selected-securities .security-#{security.id}")
    unless row
      row = @selectedList.addSecurity(security)
      @button.removeAttribute('disabled')
    @scrollToSelectedSecurity(row)

  checkNotExistingHolding: (security) ->
    if @existing.indexOf(parseInt(security.id)) > -1
      PopupMessage.alert("#{security.name} is already in your portfolio")
      return true
    false

  scrollToSelectedSecurity: (security) ->
    @selectedList.securitySelected(security.dataset.id)
    scroll_to_element ".security-#{security.dataset.id}"

  selectedSecurityMenu: ->
    builder = new MenuBuilder('Modify', 'Remove')
    builder.build()

  prepareRow: (element) ->
    element.addEventListener 'click', (e) => @selectedSecurityRowClicked(element, e.clientX, e.clientY)

  selectedSecurityRowClicked: (element, x, y) ->
    @menu.setAbsolutePosition(x, y)
    @selectedRow = element
    @menu.open = !@menu.open
    false

  submitButtonClicked: ->
    params = params_with_csrf_token()
    @addQuantityToParams(params, security, i) for security, i in document.querySelectorAll('.selected-securities li.security')

    fetch("/portfolios/#{@id}/holdings", method: 'POST', body: params, headers: {'Content-Type': 'application/x-www-form-urlencoded', 'X-Requested-With': 'XMLHttpRequest'}).then (response) =>
      @parent.formComplete()

  addQuantityToParams: (params, security, i) ->
    params.append("holdings[][share_id]", security.dataset.id)    
    params.append("holdings[][quantity]", security.querySelector('input').value)