import {element_with_class, element_with_content} from 'utils/material_elements'
import {MDCDialog} from '@material/dialog'

export class PopupMessage

  @alert: (message) ->
    @addAndShowDialog @alertDialog(message)

  @confirm: (title, message) ->
    @addAndShowDialog @confirmDialog(title, message)

  @showPopup: (content, buttons...) ->
    @addAndShowDialog @buildSurface(content, buttons...)

  @addAndShowDialog: (div) ->
    document.body.append div
    @dialog = new MDCDialog(div)
    @dialog.open()
    @dialog

  @buildSurface: (content, buttons...) ->
    surface = @surface()
    surface.append element_with_content('div', content, 'mdc-dialog__content', 'dialog-with-icons', 'mdc-menu-surface--anchor')
    if buttons.length > 0
      surface.append @buttonPanel(buttons...)
    else
      surface.append @closeButtonPanel()
    @buildDialog surface

  @alertDialog: (message) ->
    surface = @surface()
    surface.append element_with_content('div', message, 'mdc-dialog__content')
    surface.append @okButtonPanel()
    @buildDialog surface

  @confirmDialog: (title, message) ->
    surface = @surface()
    surface.append element_with_content('h2', title, 'mdc-dialog__title')    
    surface.append element_with_content('div', message, 'mdc-dialog__content')
    surface.append @yesCancelButtonPanel()
    @buildDialog surface

  @buildDialog: (surface) ->
    div = element_with_class('div', 'mdc-dialog')
    container = element_with_class('div', 'mdc-dialog__container')
    container.append surface
    div.append container
    div.append element_with_class('div', 'mdc-dialog__scrim')
    div

  @surface: ->
    surface = element_with_class('div', 'mdc-dialog__surface')
    surface.setAttribute('role', 'alertdialog')
    surface.setAttribute('aria-modal', 'true')
    surface

  @closeIcon: ->
    icon = element_with_content('div', '&#xe5cd;', 'material-icons', 'close-icon')
    icon.addEventListener 'click', => @dialog.close()
    element_with_content('div', icon, 'icon-bar')

  @yesCancelButtonPanel: -> @buttonPanel @cancelButton(), @yesButton()
  @okButtonPanel: -> @buttonPanel @okButton()
  @closeButtonPanel: -> @buttonPanel @closeButton()

  @buttonPanel: (buttons...) ->
    null if buttons.length > 0
    div = element_with_class('div', 'mdc-dialog__actions')
    div.append(button) for button in buttons
    div

  @yesButton:     -> @button('Yes', 'accept')
  @cancelButton:  -> @button('Cancel', 'close')
  @okButton:      -> @button('OK', 'close')
  @closeButton:   -> @button('Close', 'close')

  @button: (label, action, is_default) ->
    button = element_with_class('button', 'mdc-button', 'mdc-dialog__button')
    button.dataset.mdcDialogButtonDefault = true if is_default
    button.append element_with_class('div', 'mdc-button__ripple')
    button.append element_with_content('div', label, 'mdc-button__label')
    button.dataset.mdcDialogAction = action
    button