import * as material from 'utils/material_elements'
import {element_with_class, element_with_content} from 'utils/material_elements'
import {form_params, form_element, hidden_field} from 'utils/form_utils'
import {PortfolioForm} from 'generator/portfolio_form'
import {CookieManager} from 'utils/cookie_manager'
import {MDCTextField} from '@material/textfield'
import {MoneyFormat} from 'utils/money_format'
import {FlashMessage} from 'components/flash'
import {PopupMessage} from 'components/popup_message'

export class ModifyPortfolioForm extends PortfolioForm

  constructor: (@portfolio, @parent) ->
    @portfolioCurrency = @portfolio.currency
    super(null, @parent)

  load: ->
    @emptyTab()
    @tab.append @grid()
    @scrollToContent()

  grid: ->
    div = element_with_class('div', 'mdc-layout-grid__cell--span-12')
    div.append @title()
    div.append @paragraph()
    div.append @form()
    div

  title: ->
    element_with_content 'h2', 'Edit Portfolio', 'mdc-typography--headline5'

  paragraph: ->
    element_with_content 'p', "Optionally set an opening balance for the portfolio to track your cash position.", 'mdc-typography--subtitle1'

  form: ->
    form = form_element("/portfolios/#{@portfolio.id}", 'POST', 'edit_portfolio')
    form.classList.add('material-form', 'portfolio-form')
    form.append hidden_field('portfolio[id]', @portfolio.id)
    form.append hidden_field('_method', 'patch')
    form.append @nameInput()
    form.append @balanceInput()
    form.append @currencyInput()
    form.append @buttons()
    form

  nameInput: ->
    input = material.text_field('Portfolio name', 'portfolio[name]', @portfolio.name)
    @name = new MDCTextField(input)
    element_with_content('div', input, 'input')

  balanceInput: ->
    input = material.money_input_field('Opening balance', 'portfolio[opening_balance]', MoneyFormat.formatForInput(@portfolio.ob), @portfolioCurrency.unit)
    @balance = new MDCTextField(input)
    element_with_content('div', input, 'input')

  buttons: ->
    div = element_with_class('div', 'buttons')
    div.append @submitButton()
    div.append @cancelButton()
    div

  submitButton: ->
    @submit_button = material.submit_button('Update portfolio', true)
    @submit_button.addEventListener 'click', (e) => @submitClicked(e)
    @submit_button

  submitClicked: (e) ->
    e.preventDefault()
    @submit_button.setAttribute('disabled', true)
    if @currencyHasChanged()
      @showChangeCurrencyMessage()
    else
      @submitForm()

  showChangeCurrencyMessage: ->
    dialog = PopupMessage.alert("Note: changing currency applies the best exchange rate available.\n\nThis takes a few minutes to complete.")
    dialog.listen 'MDCDialog:closed', (event) => @submitForm()

  currencyHasChanged: ->
    @portfolioCurrency.id != parseInt(@currency.value)

  submitForm: ->
    fetch("/portfolios/#{@portfolio.id}", method: 'POST', body: @portfolioFormParameters(), headers: {'Content-Type': 'application/x-www-form-urlencoded', 'X-Requested-With': 'XMLHttpRequest'}).then (response) => @receiveResponse(response)

  cancelButton: ->
    button = material.link_button('Cancel', '/portfolios')
    button.addEventListener 'click', (e) => @cancelClicked(e)
    button

  cancelClicked: (e) ->
    e.preventDefault()
    @parent.reload()

  receiveResponse: (response) ->
    response.json().then (json) => @processResponse(json)

  processResponse: (data) ->
    if data.errors
      FlashMessage.error("Unable to save portfolio")
      @showErrors(data.errors)
      @submit_button.removeAttribute('disabled')
    else
      if @currencyHasChanged()
        FlashMessage.success("Please wait... portfolio is updating")
      else
        FlashMessage.success("Portfolio saved successfully")
      @parent.load()

  showErrors: (errors) ->
    div = document.querySelector('#form-errors')
    div.remove() if div
    div = element_with_class('div', 'error-message')
    div.setAttribute('id', 'form-errors')
    div.append element_with_content('span', msg, 'error') for msg in errors
    form = document.querySelector('form#edit_portfolio')
    form.appendChild div