import {MDCTextField} from '@material/textfield'
import {MDCCheckbox} from '@material/checkbox'
import {MDCTextFieldHelperText} from '@material/textfield/helper-text'
import {params_with_csrf_token} from 'utils/form_utils'
import * as validations from 'utils/validations'
import {generate_recaptcha_token} from 'utils/form_utils'

export class SetPassswordForm

  constructor: ->
    @prepareTextFields()
    @prepareForm()

  prepareTextFields: ->
    new MDCTextField(document.getElementById('user_email').parentElement.parentElement)
    @passwordField = @prepareTextField 'user_password'
    @setSubmitButtonDisabledState()

  prepareTextField: (id) ->
    el = document.getElementById(id)
    el.addEventListener 'keyup', (e) => @textBoxKeyPress(e)
    el.addEventListener 'change', (e) => @setSubmitButtonDisabledState()
    el.addEventListener 'paste', (e) => @setSubmitButtonDisabledState()
    try
      new MDCTextField(el.parentElement.parentElement)
    catch e

  textBoxKeyPress: (e) ->
    if e.keyCode == 13
      @checkSubmitForm(e)
    else
      @setSubmitButtonDisabledState()

  setSubmitButtonDisabledState: ->
    document.querySelector('form#set-password button[type=submit]').disabled = !validations.isValidPassword(@passwordField.value)

  prepareForm: ->
    document.getElementById('set-password').addEventListener 'submit', (e) => @checkSubmitForm(e)

  checkSubmitForm: (e) ->
    e.preventDefault()
    @removeAllErrors()
    if !@isAgreeToTermsChecked()
      @showError '.agree-to-terms', 'To use this service you must agree to the privacy policy and service terms and conditions'
    else
      @submitForm()
    false

  submitForm: ->
    document.querySelector('form#set-password button[type=submit]').disabled = true
    generate_recaptcha_token =>
      headers = {'Content-Type': 'application/x-www-form-urlencoded', 'X-Requested-With': 'XMLHttpRequest'}
      fetch('/users/set_password', method: 'POST', body: @formParams(), headers: headers).then (response) => @formSubmitComplete(response)

  isAgreeToTermsChecked: -> document.getElementById('user_agree_to_terms').checked

  removeAllErrors: ->
    el.remove() for el in document.querySelectorAll('form#set-password .checkbox-error')
    error = document.querySelector('#set-password-container .error-message')
    error.remove() if error

  showError: (div, msg) ->
    @removeAllErrors()
    outer = document.createElement('div')
    outer.classList.add('mdc-text-field-helper-line', 'checkbox-error')
    inner = document.createElement('div')
    inner.classList.add('mdc-text-field-helper-text', 'mdc-text-field-helper-text--validation-msg')
    inner.innerText = msg
    outer.append inner
    box = document.querySelector("#{div} label")
    box.append outer

  formParams: ->
    params = params_with_csrf_token()
    params.append('user[password]', @passwordField.value)
    @appendValueToParams(params, 'user_perishable_token')
    @appendValueToParams(params, 'recaptcha_token')
    params.append('user[agree_to_terms]', document.querySelector('.agree-to-terms label').innerText)
    params

  appendValueToParams: (params, input) ->
    token = document.getElementById(input)
    params.append(token.name, token.value)    

  formSubmitComplete: (response) ->
    response.json().then (json) => 
      if json.success
        document.querySelector('#password-set-container').classList.add 'visible'
        document.querySelector('#set-password-container').classList.remove 'visible'
      else
        form = document.querySelector('#set-password-container')
        div = document.createElement('div')
        div.append json.error
        div.classList.add 'error-message'
        form.append div
        

new SetPassswordForm()
