import {TransactionForm} from 'generator/transaction_form'
import {MDCList} from '@material/list'
import {MDCCheckbox} from '@material/checkbox'
import {element_with_class, element_with_content, submit_button} from 'utils/material_elements'
import {form_element, form_params, hidden_field} from 'utils/form_utils'
import {FlashMessage} from 'components/flash'
import {MoneyFormat} from 'utils/money_format'
import {EventTracking} from 'utils/amplitude'
import Choices from 'choices.js'

export class ModifyTransactionForm extends TransactionForm

  constructor: (@transaction, @parent) ->
    @portfolioCurrency = @parent.portfolioCurrency()
    @tab = document.querySelector('#portfolio-data')
    @type = switch @transaction.type_class
      when 'purchase' then 1
      when 'sale' then 2
      when 'deposit' then 3
      when 'withdrawal' then 4
    @load()
  
  load: ->
    @emptyTab()
    @grid = element_with_class('div', 'mdc-layout-grid__cell--span-12')
    @grid.append element_with_content('div', 'Modify transaction', 'mdc-typography--headline5')
    @grid.append @gridContent()
    @tab.append @grid
    @populateForm()
    @prepareDatePicker()
    @scrollToContent()

  pageTitle: ->
    switch @transaction.type_class
      when 'purchase' then "Share purchase: #{@transaction.share_name}"
      when 'sale' then "Share sale: #{@transaction.share_name}"
      else @transaction.type

  gridContent: -> 
    step = element_with_class('div', 'step', 'active', 'allow-overflow')
    div = element_with_class('div', 'inner')
    div.append element_with_content('h3', @pageTitle(), 'mdc-typography--headline6')
    div.append @editTransactionForm()
    step.append div
    step

  editTransactionForm: ->
    @form = form_element(@formUrl(), 'POST', 'edit_transaction')
    @form.classList.add 'material-form', 'transaction'
    @form.append hidden_field('transaction[id]', @transaction.id)
    @form.append @transactionDateInput()
    @form.append @quantityInput() if @transaction.type_class is 'purchase' or @transaction.type_class is 'sale'
    @form.append @priceAndCurrencyInput(@priceInputLabel())
    @form.append @exchangeRateInput() if @showExchangeRateInput()
    @form.append @commissionInput() if @transaction.type_class is 'purchase' or @transaction.type_class is 'sale'
    @form.append @stampDutyInput() if @transaction.type_class is 'purchase'
    @form.append @totalBox()
    @form.append @formButtons()
    @form.addEventListener 'submit', (e) => @formSubmitted(e)
    @form

  showExchangeRateInput: ->
    (@transaction.type_class is 'purchase' or @transaction.type_class is 'sale')

  priceInputLabel: ->
    switch @transaction.type_class
      when 'purchase' then 'Purchase price'
      when 'sale' then 'Sale price'
      else "Amount (#{@portfolioCurrency.unit})"

  formSubmitted: (e) ->
    e.preventDefault()
    return false unless @validateForm()
    params = form_params('#edit_transaction')
    params.append('_method', 'patch')
    params.append('transaction[currency_id]', @selectedCurrencyField('value'))
    fetch(@formUrl(), method: 'POST', body: params, headers: {'Content-Type': 'application/x-www-form-urlencoded', 'X-Requested-With': 'XMLHttpRequest'}).then (response) => @handleFormResponse(response)

  validateForm: ->
    errors = @validateCommonFormFields()
    @showErrors(errors) if errors.length > 0
    errors.length == 0

  formUrl: -> "/transactions/#{@transaction.id}"

  handleFormResponse: (response) ->
    response.json().then (json) =>
      if json.success
        FlashMessage.success "Transaction saved successfully"
        EventTracking.track('ModifyTransaction')
        @parent.reload()
        @scrollToContent()
      else
        FlashMessage.error "Error saving this transaction"
        @showErrors(json.errors)

  submitButtonTitle: -> "Update Transaction"

  populateForm: ->
    @setFieldValue document.querySelector('.date'),  @transaction.fdate
    @setFieldValue document.querySelector('.price'), @transaction.price
    @setFieldValue document.querySelector('.quantity'), @transaction.qty.replace(/,/g, '')
    @setFieldValue document.querySelector('.commission'), @transaction.cmsn
    @setFieldValue document.querySelector('.exchange-rate'), @transaction.fx if document.querySelector('.exchange-rate')
    @setFieldValue document.querySelector('.stamp_duty'), @transaction.stmp if document.querySelector('.stamp_duty')
    @updateCurrencySymbols()
    @updateTotal()