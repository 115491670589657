import {MDCTextField} from '@material/textfield'
import {MDCCheckbox} from '@material/checkbox'
import {MDCTextFieldHelperText} from '@material/textfield/helper-text'
import {generate_recaptcha_token} from 'utils/form_utils'

export class RegisterForm

  constructor: ->
    @prepare() if document.querySelector('body').id is 'signup'

  prepare: ->
    @addCheckBoxListeners()
    @prepareTextFields()
    @prepareForm()

  addCheckBoxListeners: ->
    @prepareCheckbox(box) for box in ['user_agree_to_terms', 'user_type_free_trial', 'user_email_subscription_attributes_notification_emails', 'user_email_subscription_attributes_friday_emails']

  prepareCheckbox: (id) ->
    i = document.getElementById(id)
    i.addEventListener 'click', => @checkBoxClicked(id)
    i

  checkBoxClicked: (id) ->
    document.getElementById('user_user_type').value = switch
      when @isFreeTrialChecked() then 7
      when @isNotificationEmailsChecked() then 8
      when @isFridayEmailsChecked() then 9
      else null

  prepareTextFields: ->
    @emailField = @prepareTextField 'user_email'
    @passwordField = @prepareTextField 'user_password'

  prepareTextField: (id) ->
    el = document.getElementById(id)
    el.addEventListener 'keyup', (e) => @textBoxKeyPress(e)
    new MDCTextField(el.parentElement)

  textBoxKeyPress: (e) ->
    if e.target.dataset and e.target.dataset.submittedvalue
      valid = e.target.value != e.target.dataset.submittedvalue
      @emailField.valid = valid
    if e.keyCode == 13
      @submitForm(e)
    else
      document.querySelector('form#new_user button[type=submit]').disabled = document.getElementById('user_email').value.length == 0 or document.getElementById('user_password').value.length == 0

  prepareForm: ->
    document.getElementById('user_email_subscription_attributes_friday_emails_text').value = document.querySelector('.friday-emails label').innerText
    document.getElementById('user_email_subscription_attributes_notification_emails_text').value = document.querySelector('.notifications label').innerText
    document.getElementById('new_user').addEventListener 'submit', (e) => @submitForm(e)

  submitForm: (e) ->
    e.preventDefault()
    @removeAllErrors()
    email = document.getElementById('user_email')
    email.value = email.value.replace(/\s+/gm,'')

    if !@isAgreeToTermsChecked()
      @showError '.agree-to-terms', 'To use this service you must agree to the privacy policy and service terms and conditions'
    else if @noOptionSelected()
      @showError '.free-trial', 'You must select at least one sign up option'
    else
      document.getElementById('submit-button').disabled = true
      generate_recaptcha_token -> document.getElementById('new_user').submit()

  removeAllErrors: ->
    el.remove() for el in document.querySelectorAll('form#new_user .checkbox-error')

  noOptionSelected: ->
    not @isNotificationEmailsChecked() and not @isFridayEmailsChecked() and not @isFreeTrialChecked()

  showError: (div, msg) ->
    @removeAllErrors()
    outer = document.createElement('div')
    outer.classList.add('mdc-text-field-helper-line', 'checkbox-error')
    inner = document.createElement('div')
    inner.classList.add('mdc-text-field-helper-text', 'mdc-text-field-helper-text--validation-msg')
    inner.innerText = msg
    outer.append inner
    box = document.querySelector("#{div} label")
    box.append outer

  isAgreeToTermsChecked: -> document.getElementById('user_agree_to_terms').checked
  isNotificationEmailsChecked: -> document.getElementById('user_email_subscription_attributes_notification_emails').checked
  isFridayEmailsChecked: -> document.getElementById('user_email_subscription_attributes_friday_emails').checked
  isFreeTrialChecked: -> document.getElementById('user_type_free_trial').checked

new RegisterForm()
