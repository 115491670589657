import {MDCList} from '@material/list'
import {MDCTextField} from '@material/textfield'
import {CompanySearch} from 'components/company_search'
import {FlashMessage} from 'components/flash'
import {SelectedSecuritiesList} from 'components/selected_securities_list'
import {scroll_to_element} from 'utils/scroller'
import {params_with_csrf_token} from 'utils/form_utils'
import {close_banner} from 'components/banner'
import {User} from 'utils/user'
import {PopupMessage} from 'components/popup_message'
import {element_with_class, element_with_content, link_button} from 'utils/material_elements'
import {generate_recaptcha_token} from 'utils/form_utils'

export class NotificationsTable

  constructor: ->
    @prepare()

  prepare: ->
    @selectedList = new SelectedSecuritiesList('#watchlist', this)
    @prepareSearch()
    @preparePopular()
    @prepareButtons()
    @prepareSignupForm() unless User.isLoggedIn()
    @errorBox = document.querySelector('#content .error-message')

  preparePopular: ->
    @popularList = new MDCList(document.querySelector('#popular'))
    @popularList.listen 'MDCList:action', (action) =>
      item = @popularList.listElements[action.detail.index]

      @addNotificationRow item.dataset
      item.classList.add 'fadeout'
      setTimeout (=> item.remove()), 500
  
  prepareSearch: ->
    new CompanySearch('notifications-search', false, false, (selection) => @addNotification(selection.value))

  addNotification: (row) ->
    data =
      id: row.id
      image: row.image
      name: "(#{row.ticker}) #{row.name}"
    @addNotificationRow(data)
    document.querySelector('#notifications-search-box').value = ''

  addNotificationRow: (data) ->
    security = document.querySelector("#watchlist .security-#{data.id}")
    document.querySelector('.destination-list').classList.add 'shown'
    unless security
      security = @selectedList.addSecurity(data)

    @scrollToWatchListSecurity(security)
    @showSaveButton()

  scrollToWatchListSecurity: (security) ->
    @selectedList.securitySelected(security.dataset.id)
    scroll_to_element ".security-#{security.dataset.id}"

  prepareButtons: ->
    document.querySelector('.save-notifications').addEventListener 'click', (e) =>
      e.preventDefault()
      @saveButtonClicked()

    document.querySelector('.copy-to-portfolio').addEventListener 'click', (e) =>
      e.preventDefault()
      @copyToPortfolioButtonClicked()

  saveButtonClicked: ->
    if !User.isLoggedIn()
      if @isUserFormVisible()
        @validateAndSubmitUserForm()
      else
        @displayUserForm()
        scroll_to_element '#sign-up-form'
    else if @validateAgreeToNotifications()
      @submitWatchlistForm()

  isUserFormVisible: -> @form.classList.contains 'open'
  displayUserForm: -> @form.classList.add 'open'

  hideUserForm: ->
    if @form
      @form.classList.remove 'open'
      setTimeout (=> @form.remove()), 500
      document.location = "/signup/success?email=#{@email_field.value}"
    
  validateAndSubmitUserForm: ->
    if @validateUserForm()
      @submitUserForm()

  validateUserForm: ->
    document.querySelector('.agree-to-notifications').classList.remove 'error'
    document.querySelector('.agree-to-terms').classList.remove 'error'

    if @email_field.value.length == 0 or !@email_field.valid
      @email_field.valid = false
      @errorBox.innerText = "Please enter a valid email address"
      return false
    if @password_field.value.length == 0 or !@password_field.valid
      @password_field.valid = false
      @errorBox.innerText = "Please enter a valid password with at least 6 characters"
      return false

    return false unless @validateAgreeToTerms()
    @validateAgreeToNotifications()

  validateAgreeToNotifications: ->
    return true unless document.querySelector('.agree-to-notifications')
    document.querySelector('.agree-to-notifications').classList.remove 'error'
    if !document.querySelector('.agree-to-notifications input[type=checkbox]').checked
      @errorBox.innerText = "You must agree to receive dividend email notifications"
      document.querySelector('.agree-to-notifications').classList.add 'error'
      return false
    true

  validateAgreeToTerms: ->
    document.querySelector('.agree-to-terms').classList.remove 'error'
    if !document.querySelector('#user_agree_to_terms').checked
      @errorBox.innerText = "You must agree to the Terms and Conditions and Privacy Policy"
      document.querySelector('.agree-to-terms').classList.add 'error'
      return false
    true

  submitUserForm: ->
    generate_recaptcha_token => @submitForm('/users', @userFormData())

  submitWatchlistForm: ->
    @submitForm '/watch_lists', @watchlistFormData()

  submitForm: (path, body) ->
    @loadingIcon or= document.querySelector '.loading-icon'
    @loadingIcon.classList.add 'on'
    @doneIcon or= document.querySelector '.done-icon'
    @doneIcon.classList.remove 'fade', 'on'
    if @errorBox
      @errorBox.innerHTML = ""

    fetch(path, method: 'POST', body: body, headers: {'Content-Type': 'application/x-www-form-urlencoded', 'X-Requested-With': 'XMLHttpRequest'}).then (response) => @handlePostResponse(response)

  handlePostResponse: (response) ->
    @loadingIcon.classList.remove 'on'
    response.json().then (json) =>
      if json.success
        @doneIcon.classList.add 'on'
        setTimeout (=> @doneIcon.classList.add('fade')), 100
        setTimeout (=> @doneIcon.classList.remove('on')), 1100
        @hideUserForm()
        FlashMessage.success("Your notification watchlist has been saved successfully")
        @updateTextWithCounts()
        @hideSaveButton()
        close_banner()
      else
        @errorBox.innerHTML = json.error

  userFormData: ->
    params = @watchlistFormData()
    @addParam params, '#user_email'
    @addParam params, '#user_password'
    @addParam params, '#user_user_type'
    @addParam params, '#user_agree_to_terms'
    @addParam params, '#user_email_subscription_attributes_notification_emails'
    @addParam params, '#recaptcha_token'
    params

  addParam: (params, field_id) ->
    field = document.querySelector(field_id)
    params.append field.name, field.value

  watchlistFormData: ->
    params = params_with_csrf_token()
    @addNotificationCheckboxParams(params)
    for row, i in document.querySelectorAll('#watchlist .security')
      param = if User.isLoggedIn() then "shares[#{i}][id]" else "user[shares[#{i}][id]]"
      params.append(param, row.dataset.id)
      param = if User.isLoggedIn() then "shares[#{i}][quantity]" else "user[shares[#{i}][quantity]]"
      params.append(param, row.querySelector('input').value)
    params

  addNotificationCheckboxParams: (params) ->
    input = document.querySelector('#notification_emails')
    if input and input.checked
      params.append('email_subscription[notification_emails]', '1')
      label = document.querySelector('.agree-to-notifications label')
      params.append('email_subscription[notification_emails_text]', label.innerText)

  updateTextWithCounts: ->
    count = document.querySelectorAll('#watchlist li').length
    title = document.querySelector('#notifications_title')
    title.innerText = "#{count} notification#{if count is 1 then "" else "s"}"

    para = document.querySelector('#notifications_paragraph')
    text = "You have #{count} email notification#{if count is 1 then "" else "s"}. "
    if count is 0
      text += "Select a company to receive an email when they declare a dividend."
    else
      text += "You will receive an email when these companies declare a dividend."
    para.innerText = text

  prepareSignupForm: ->
    @form = document.querySelector '#sign-up-form'
    @email_field = new MDCTextField(document.querySelector('#user_email').parentElement)
    @password_field = new MDCTextField(document.querySelector('#user_password').parentElement)

  showSaveButton: ->
    document.querySelector('.save-notifications').classList.remove 'hidden'
    document.querySelector('.copy-to-portfolio').classList.add 'hidden'

  hideSaveButton: ->      
    document.querySelector('.save-notifications').classList.add 'hidden'
    if document.querySelectorAll('#watchlist .security').length > 0
      document.querySelector('.copy-to-portfolio').classList.remove 'hidden'

  selectedSecuritiesUpdated: -> # called from SelectedSecuritiesList
    @showSaveButton()

  copyToPortfolioButtonClicked: ->
    if User.hasActiveSubscription()
      popup = PopupMessage.confirm("Copy Notifications To A Portfolio?", "This will create a portfolio called \"My Notifications\" in the Generator. Do you want to continue?")
      popup.listen 'MDCDialog:closed', (event) =>
        @copyToPortfolio(popup) if event.detail.action == 'accept'
    else if User.isExpired()
      @showAccountExpiredPopup()
    else if User.isLoggedIn()
      @showActivateSubscriptionPopup()

  showAccountExpiredPopup: ->
    message = element_with_content('div', 'Your DividendMax subscription has expired so you do not have access to create a portfolio.')
    message.append element_with_content('p', link_button('Extend Subscription', '/payments/new', true, true))

    div = document.createElement('div')
    div.append element_with_content('h2', 'Subscription Expired', 'mdc-dialog__title')    
    div.append element_with_content('div', message, 'mdc-dialog__content')

    PopupMessage.showPopup(div, PopupMessage.okButton())

  showActivateSubscriptionPopup: ->
    message = element_with_content('div', 'To create a portfolio you need to activate your DividendMax subscription.')
    message.append element_with_content('p', link_button('Begin Free Trial', '/begin-free-trial', true, true))

    div = document.createElement('div')
    div.append element_with_content('h2', 'DividendMax Subscription Required', 'mdc-dialog__title')    
    div.append element_with_content('div', message, 'mdc-dialog__content')

    PopupMessage.showPopup(div, PopupMessage.okButton())

  copyToPortfolio: (popup) ->
    path = "/dividends/notifications/create_portfolio"
    fetch(path, method: 'POST', body: params_with_csrf_token(), headers: {'Content-Type': 'application/x-www-form-urlencoded', 'X-Requested-With': 'XMLHttpRequest'}).then (response) ->
      response.json().then (json) ->
        if json.success
          FlashMessage.success "Your portfolio is being created now. Click Generator to see it."
          popup.close()
        else
          FlashMessage.error "Unable to create portfolio: #{json.error}"
          popup.close()

new NotificationsTable() if document.querySelector('body').id is 'notifications'