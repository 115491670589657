import {GeneratorTab} from 'generator/generator_tab'
import {User} from 'utils/user'
import {Transaction} from 'generator/transaction'
import {TransactionHolding} from 'generator/transaction_holding'
import {NewTransactionForm} from 'generator/new_transaction_form'
import {element_with_class, element_with_content, link_button, submit_button} from 'utils/material_elements'
import {PopupMessage} from 'components/popup_message'
import {MoneyFormat} from 'utils/money_format'

export class Transactions extends GeneratorTab
  
  load: ->
    fetch("/portfolios/#{@id}/transactions.json").then (response) => response.json().then (json) =>
      @buildPage(json)
      @setPortfolioValue(json)

  buildPage: (@json) ->
    @emptyTab()
    grid = element_with_class('div', 'mdc-layout-grid__cell--span-12')
    grid.append @buttonsAndBalance() if @json.can_edit or @json.close_bal
    grid.append @transactionContainer() if @json.transactions.length > 0
    grid.append @holdingsDiv() if @json.transactions.length > 0 and @json.holdings.length > 0
    grid.append @bottomBar() if @json.close_bal and @json.transactions.length > 0
    grid.append(@emptyMessage()) if @json.transactions.length is 0
    @tab.append grid

  buttonsAndBalance: ->
    div = element_with_class('div', 'top-bar')
    div.classList.add 'with-buttons' if @json.can_edit
    div.append @buttons() if @json.can_edit
    div.append @closingBalance() if @json.close_bal
    div

  emptyMessage: -> element_with_content('div', 'No transactions', 'notice')
  transactionContainer: -> element_with_content('div', @transactionList(), 'table-container')
  transactionList: -> element_with_content('div', @transactionTable(), 'mdc-data-table', 'transactions-table')
  transactionTable: ->
    table = element_with_class('table', 'mdc-data-table__table')
    table.append @tableHeader()
    table.append @tableBody()
    table

  tableHeader: -> element_with_content('THEAD', @headerRow())
  headerRow: ->
    tr = element_with_class('tr', 'mdc-data-table__header-row')    
    tr.append element_with_content('th', 'Date', 'mdc-data-table__header-cell')
    tr.append element_with_content('th', 'Company', 'mdc-data-table__header-cell')
    tr.append element_with_content('th', 'Type', 'mdc-data-table__header-cell')
    tr.append element_with_content('th', 'Amount', 'mdc-data-table__header-cell')
    tr.append element_with_content('th', 'Balance', 'mdc-data-table__header-cell') if @json.close_bal
    tr

  tableBody: ->
    body = element_with_class('tbody', 'mdc-data-table__content')
    body.append @transactionRow(row) for row in @json.transactions
    body

  transactionRow: (transaction) ->
    t = new Transaction(transaction, this, @json.open_bal, @json.can_edit)
    t.html()

  holdingsDiv: ->
    div = element_with_content('div', element_with_content('h2', 'Holdings without transactions', 'mdc-typography--headline5'), 'holding-without-transactions')
    div.append @holdingsContainer()
    div

  holdingsContainer: -> element_with_content('div', @holdingsList(), 'table-container')
  holdingsList: -> element_with_content('div', @holdingsTable(), 'mdc-data-table', 'holdings-table')
  holdingsTable: ->
    table = element_with_class('table', 'mdc-data-table__table')
    table.append @holdingsTableHeader()
    table.append @holdingsTableBody()
    table

  holdingsTableHeader: -> element_with_content('THEAD', @holdingsHeaderRow())
  holdingsHeaderRow: ->
    tr = element_with_class('tr', 'mdc-data-table__header-row')    
    tr.append element_with_content('th', 'Company', 'mdc-data-table__header-cell')
    tr.append element_with_content('th', 'Outlay', 'mdc-data-table__header-cell')
    tr.append element_with_content('th', 'Dividends', 'mdc-data-table__header-cell')
    tr.append element_with_content('th', 'Sold', 'mdc-data-table__header-cell')
    tr.append element_with_content('th', 'Balance', 'mdc-data-table__header-cell') if @json.close_bal
    tr

  holdingsTableBody: ->
    body = element_with_class('tbody', 'mdc-data-table__content')
    body.append @holdingRow(row) for row in @json.holdings
    body

  holdingRow: (holding) ->
    t = new TransactionHolding(holding, this, @json.open_bal)
    t.html()

  bottomBar: -> element_with_content('div', @openingBalance(), 'bottom-bar')
  closingBalance: -> @balanceRow 'Closing Balance', @json.close_bal
  openingBalance: -> @balanceRow 'Opening Balance', @json.open_bal

  balanceRow: (text, val) ->
    div = element_with_class('div', 'balance')
    div.append element_with_content('span', text, 'mdc-typography--caption')
    div.append element_with_content('div', MoneyFormat.formatFull(val, @json.currency.code), 'amount', 'mdc-typography--headline6')
    div.classList.add('neg') if val < 0
    div

  buttons: ->
    div = element_with_class('div', 'link-buttons')
    div.append @addTransactionButton()
    div.append @exportTransactionsButton()
    div

  addTransactionButton: ->
    button = submit_button('Add Transaction', true, false)
    button.addEventListener 'click', (e) => @loadNewTransactionForm(e)
    button

  loadNewTransactionForm: (e) ->
    e.preventDefault()
    form = new NewTransactionForm(@id, this)
    form.load()

  exportTransactionsButton: ->
    button = submit_button('Export to Excel', false, false, true)
    button.addEventListener 'click', (e) => @exportTransactionsClicked(e)
    button.setAttribute('disabled', true) if @json.transactions.length is 0
    button

  exportTransactionsClicked: (e) ->
    if User.isPaidActive()
      document.location = @exportUrl()
    else
      e.preventDefault()
      @showNotPaidPopup()

  exportUrl: -> if User.isPaidActive() then "/portfolios/#{@id}/transactions/export.xls" else '#transactions'

  showNotPaidPopup: ->
    PopupMessage.alert("Sorry, this feature is only available on paid subscriptions.")

  paymentLinks: ->
    $('<div />')
      .addClass('payment-links')
      .append $('<a />').addClass('orange-button').attr('href', '/payments/new').text("Pay For DividendMax")
      .append $('<a />').addClass('cancel').attr('href', '#transactions').text("Cancel").on('click', => @popup.closePopup())

  portfolioCurrency: ->
    @json.currency