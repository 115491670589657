import {MDCMenu} from '@material/menu'
import {SecurityListItem} from 'components/security_list_item'

export class SelectedSecuritiesList

  constructor: (selector, @parent) ->
    @list = document.querySelector(selector)
    @watchlist = {}
    @selectedRow = null
    @prepareMenu()
    @prepareExistingWatchlist(row) for row in @list.querySelectorAll('.security')

  prepareExistingWatchlist: (element) ->
    @watchlist[element.dataset.id] = new SecurityListItem(element, @parent)
    @prepareRow(element)

  prepareMenu: ->
    @menu = new MDCMenu(document.querySelector('.mdc-menu'))
    @menu.listen 'MDCMenu:selected', (event) =>
      if event.detail.index is 0
        @showEditInput()
      else
        @removeSelectedRow()

  addSecurity: (data) ->
    item = new SecurityListItem(data, @parent)
    @watchlist[data.id] = item
    @list.querySelector('.empty').classList.add('hidden')
    row = item.htmlForSelection()
    @list.append row
    @prepareRow(row)
    row

  prepareRow: (element) ->
    element.addEventListener 'click', (e) => @securityRowClicked(element.dataset.id, e.clientX, e.clientY)

  securityRowClicked: (id, x, y) ->
    id = parseInt(id)
    if !@selectedRow or @selectedRow.id != id
      @removeEditModeOnAllRows()
      @selectedRow = @watchlist[id]
    
    @menu.open = !@menu.open unless @selectedRow.editMode
    @menu.setAbsolutePosition(x, y) if @menu.open
    false

  removeEditModeOnAllRows: ->
    item.editModeOff() for item in Object.values(@watchlist)

  securitySelected: (id) ->
    @selectedRow = @watchlist[id]
    setTimeout (=> @showEditInput()), 100

  showEditInput: ->
    @selectedRow.beginEdit()

  removeSelectedRow: ->
    @selectedRow.row.classList.add 'fadeout'

    removeRow = @selectedRow
    @selectedRow = null
    setTimeout (=> @removeRow(removeRow)), 500

  removeRow: (item) ->
    item.row.remove()
    delete @watchlist[item.id]
    if Object.keys(@watchlist).length == 0
      @list.querySelector('.empty').classList.remove('hidden')
    if @parent
      @parent.selectedSecuritiesUpdated()