import {MDCTextField} from '@material/textfield'
import {generate_recaptcha_token} from 'utils/form_utils'

export class LoginForm

  constructor: ->
    @prepare() if document.querySelector('body').id is 'login'

  prepare: ->
    @prepareFormSubmit()
    @emailField = @prepareTextField 'user_session_email'
    @passwordField = @prepareTextField 'user_session_password'
    @setSubmitButtonDisabledState()

  prepareFormSubmit: ->
    button = document.querySelector('form#new_user_session button[type=submit]')
    button.addEventListener 'click', (e) => @triggerFormSubmit(e)

  triggerFormSubmit: (e) ->
    e.preventDefault()
    button = document.querySelector('form#new_user_session button[type=submit]')
    unless button.disabled
      button.disabled = true
      generate_recaptcha_token -> document.querySelector('form#new_user_session').submit()

  prepareTextField: (id) ->
    el = document.getElementById(id)
    el.addEventListener 'keyup', (e) => @textBoxKeyPress(e)
    el.addEventListener 'change', (e) => @setSubmitButtonDisabledState()
    el.addEventListener 'paste', (e) => @setSubmitButtonDisabledState()
    new MDCTextField(el.parentElement.parentElement)

  textBoxKeyPress: (e) ->
    if e.keyCode == 13
      @triggerFormSubmit(e)
    else
      @setSubmitButtonDisabledState()

  setSubmitButtonDisabledState: ->
    disabled = document.getElementById('user_session_email').value.length == 0 or document.getElementById('user_session_password').value.length == 0
    document.querySelector('form#new_user_session button[type=submit]').disabled = disabled

new LoginForm()
