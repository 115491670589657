import {element_with_class, element_with_content, link_button} from 'utils/material_elements'
import {GeneratorTab} from 'generator/generator_tab'
import {FutureDividend} from 'generator/future_dividend'
import {MoneyFormat} from 'utils/money_format'

export class FutureIncome extends GeneratorTab

  load: ->
    fetch("/portfolios/#{@id}/income.json").then (response) => @receiveData(response)

  formComplete: ->
    @load()

  receiveData: (response) ->
    response.json().then (@json) =>
      @emptyTab()
      @buildTabContent()

  buildTabContent: ->
    grid = element_with_class('div', 'mdc-layout-grid__cell--span-12')
    grid.append @annualTotalBoxes()
    grid.append @yearData(year) for year in @json.years
    grid.append(@emptyMessage()) if @json.years.length is 0
    @tab.append grid

  emptyMessage: -> element_with_content('div', 'No future portfolio income', 'notice')

  newLink: ->
   button = link_button('Add Holding', '#', true, true)
   button.addEventListener 'click', (e) => @showNewAddHoldingForm(e)
   button

  annualTotalBoxes: ->
    div = element_with_class('div', 'horizontal-cards')
    div.append @annualTotalCard(year) for year in @json.years
    div

  annualTotalCard: (year) ->
    card = element_with_class('div', 'mdc-card', 'padded-card', 'value-card')
    card.append element_with_content('div', @labelForTotal(year), 'mdc-typography--overline', 'mdc-theme--secondary')
    card.append element_with_content('div', MoneyFormat.format(year.total, @json.currency.code), 'mdc-typography--headline5', 'portfolio-value')
    card    

  showNewAddHoldingForm: (e) ->
    e.preventDefault()
    form = new AddHoldingForm(@id, this, false)
    form.load()

  yearData: (year) ->
    div = element_with_class('div', 'year')
    div.append @monthData(month) for month in year.months
    div.append @totalRow(year)
    div

  monthData: (month) ->
    div = element_with_class('div', 'month')
    div.append @monthHeader(month)
    div.append @dividends(month.dividends)
    div.append @totalRow(month)
    div

  dividends: (dividends) ->
    list = element_with_class('ul', 'mdc-list', 'mdc-list--two-line', 'mdc-list--avatar-list')
    for dividend, i in dividends
      list.append @dividendRow(dividend)
      list.append @spacerRow() unless i+1 == dividends.length
    list

  spacerRow: ->
    row = element_with_class('li', "mdc-list-divider")
    row.setAttribute('role', "separator")
    row

  dividendRow: (dividend) ->
    d = new FutureDividend(dividend, @json.currency)
    d.html()

  monthHeader: (month) -> element_with_content('div', month.label, 'row-block-header')

  totalRow: (period) ->
    div = element_with_class('div', 'subtotal')
    div.classList.add('annual') if period.months
    div.append element_with_content('label', @labelForTotal(period))
    div.append element_with_content('span', MoneyFormat.formatFull(period.total, @json.currency.code))
    div

  labelForTotal: (period) ->
    if period.months
      today = new Date()
      if today.getFullYear() == parseInt(period.label)
        return "Remaining for #{period.label}"
    "Total for #{period.label}"