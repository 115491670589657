import {GeneratorTab} from 'generator/generator_tab'
import {element_with_class, element_with_content} from 'utils/material_elements'
import {MDCTextField} from '@material/textfield'
import {form_element, form_params} from 'utils/form_utils'
import * as material from 'utils/material_elements'
import {MoneyFormat} from 'utils/money_format'
import {FlashMessage} from 'components/flash'

export class ModifyHoldingForm extends GeneratorTab
  
  constructor: (@holding, @portfolio, @parent) ->
    super @portfolio.id, @parent

  load: ->
    @emptyTab()
    @buildContent()
    @scrollToContent()

  buildContent: ->
    div = element_with_class('div', 'mdc-layout-grid__cell--span-12')
    @tab.append div
    div.append @title()
    div.append @subtitle()
    @addForm(div)
    div

  title: ->    element_with_content 'h1', 'Modify Holding', 'mdc-typography--headline2'
  subtitle: -> element_with_content 'h3', "#{@holding.share} (#{@holding.ticker})", 'mdc-typography--headline6'

  addForm: (div) ->
    form = form_element(@formUrl(), 'POST', 'modify-holding-form')
    form.classList.add 'material-form'
    div.append form
    form.append @cannotModifyMessage() unless @holding.no_trans
    @quantityInput(form)
    @outlayOrAveragePriceInput(form)
    @dividendsReceivedInput(form)
    @currentValueBox(form)
    @buttons(form)

  formUrl: -> "/portfolios/#{@portfolio.id}/holdings/#{@holding.id}"

  cannotModifyMessage: ->
    element_with_content('div', "Cannot update this holding: values are determined by the transactions", 'notice')

  quantityInput: (form) ->
    input = @formInput("Quantity Held", 'holding[quantity]', @holding.quantity, false, => @quantityUpdated())
    form.append element_with_content('div', input, 'input')
    @quantity = new MDCTextField(input)

  outlayOrAveragePriceInput: (form) ->
    div = element_with_class('div', 'input', 'multi-input')
    form.append div
    div.append @outlayInput()
    div.append element_with_content('div', 'or', 'or')
    div.append @averagePriceInput()

  outlayInput: ->
    input = @formInput("Outlay (#{@portfolio.currency.unit})", 'holding[cumulative_outlay]', @holding.toutlay, true, => @outlayUpdated())
    @outlay = new MDCTextField(input)
    input

  averagePriceInput: ->
    input = @formInput("Average Price (#{@portfolio.currency.unit})", 'holding[cumulative_average_price]', @holding.tavg_price, true, (e) => @averagePriceUpdated(e))
    @average = new MDCTextField(input)
    input

  dividendsReceivedInput: (form) ->
    input = @formInput("Dividends Received (#{@portfolio.currency.unit})", 'holding[cumulative_dividends]', @holding.tdivs_rec, true)
    form.append element_with_content('div', input, 'input')
    @dividends = new MDCTextField(input)

  currentValueBox: (form) ->
    box = element_with_class('div', 'mdc-card', 'padded-card', 'value-card')
    form.append box
    box.append element_with_content('div', 'Current Value', 'mdc-typography--overline', 'mdc-theme--secondary')
    @currentValue = element_with_class('div', 'mdc-typography--headline5')
    @setCurrentValue()
    box.append @currentValue

  formInput: (label, name, value, isMoney, onclick) ->
    value = MoneyFormat.stripNonNumerics(value)
    value = MoneyFormat.formatForInput(value) if isMoney
    label = material.text_field(label, name, value)
    label.querySelector('input').addEventListener('keyup', onclick) if onclick
    label

  buttons: (form) ->
    div = element_with_class('div', 'buttons')
    form.append div
    div.append @submitButton() if @holding.no_trans
    div.append @cancelButton()

  submitButton: ->
    button = material.submit_button('Update holding', true)
    button.addEventListener 'click', (e) => @submitClicked(e)
    button

  submitClicked: (e) ->
    e.preventDefault()
    fetch(@formUrl(), method: 'POST', body: @formParams(), headers: {'Content-Type': 'application/x-www-form-urlencoded', 'X-Requested-With': 'XMLHttpRequest'}).then (response) => @receiveResponse(response)

  formParams: ->
    params = form_params('#modify-holding-form')
    params.append '_method', 'PATCH'
    params.append 'holding[id]', @holding.id
    params

  cancelButton: ->
    button = material.link_button('Cancel', '/portfolios')
    button.addEventListener 'click', (e) => @cancelClicked(e)
    button

  cancelClicked: (e) ->
    e.preventDefault()
    @parent.load()

  receiveResponse: (response) ->
    response.json().then (json) => @processResponse(json)

  processResponse: (data) ->
    if data.errors
      FlashMessage.error("Unable to save holding")
      @showErrors(data.errors)
    else
      FlashMessage.success("Holding saved successfully")
      @parent.load()

  quantityUpdated: ->
    @setCurrentValue()
    @outlayUpdated()

  setCurrentValue: ->
    quantity = parseInt(@quantity.value)
    if isNaN(quantity)
      @currentValue.innerText = ""
    else
      total = quantity * parseFloat(@holding.pr_lo)
      @currentValue.innerText = MoneyFormat.format(total, @portfolio.currency.code)

  outlayUpdated: ->
    quantity = parseInt(@quantity.value)
    outlay = parseFloat(@outlay.value)
    if !isNaN(quantity) and !isNaN(outlay) and quantity > 0
      @average.value = MoneyFormat.roundTo(outlay / quantity, 8)

  averagePriceUpdated: (e) ->
    unless e.keyCode is 9
      quantity = parseInt(@quantity.value)
      avg = parseFloat(@average.value)
      if !isNaN(quantity) and !isNaN(avg)
        outlay = MoneyFormat.roundTo(avg * quantity, 2)
        @outlay.value = outlay

  formSuccess: (data) ->
    FlashMessage.success("Holding Updated Successfully")
    @parent.load()

  formError: (event) ->
    FlashMessage.error("Holding Update Failed. Please try again")