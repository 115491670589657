import {Transaction} from 'generator/transaction'
import {element_with_class, element_with_content} from 'utils/material_elements'
import {MoneyFormat} from 'utils/money_format'

export class TransactionHolding extends Transaction

  constructor: (@holding, @parent, @includeBalance) ->
    @portfolioCurrency = @parent.portfolioCurrency()

  html: ->
    tr = element_with_class('tr', 'mdc-data-table__row')
    tr.append element_with_content('td', @nameAndImage(@holding), 'mdc-data-table__cell')
    tr.append element_with_content('td', MoneyFormat.formatFull(@holding.outlay, @portfolioCurrency.code), 'mdc-data-table__cell', 'mdc-data-table__header-cell--numeric')
    tr.append element_with_content('td', MoneyFormat.formatFull(@holding.divs, @portfolioCurrency.code), 'mdc-data-table__cell', 'mdc-data-table__header-cell--numeric')
    tr.append element_with_content('td', MoneyFormat.formatFull(@holding.sold, @portfolioCurrency.code), 'mdc-data-table__cell', 'mdc-data-table__header-cell--numeric')
    tr.append element_with_content('td', MoneyFormat.formatFull(@holding.balance, @portfolioCurrency.code), 'mdc-data-table__cell', 'mdc-data-table__header-cell--numeric') if @includeBalance
    tr