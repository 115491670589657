import {element_with_class, element_with_content, submit_button} from 'utils/material_elements'
import {params_with_csrf_token} from 'utils/form_utils'
import {MoneyFormat} from 'utils/money_format'
import {PopupMessage} from 'components/popup_message'
import {FlashMessage} from 'components/flash'
import {ModifyTransactionForm} from 'generator/modify_transaction_form'

export class Transaction
  
  constructor: (@transaction, @parent, @includeBalance, @canEdit) ->
    @portfolioCurrency = @parent.portfolioCurrency()

  html: ->
    tr = element_with_class('tr', 'mdc-data-table__row')
    tr.append element_with_content('td', @transaction.date, 'mdc-data-table__cell')
    tr.append element_with_content('td', @nameAndImage(@transaction) or "&nbsp;", 'mdc-data-table__cell')
    tr.append element_with_content('td', @transaction.type, 'mdc-data-table__cell')
    tr.append element_with_content('td', MoneyFormat.formatFull(@transaction.total, @portfolioCurrency.code), 'mdc-data-table__cell', 'mdc-data-table__header-cell--numeric', @numberClass(@transaction.total))
    tr.append element_with_content('td', MoneyFormat.formatFull(@transaction.balance, @portfolioCurrency.code), 'mdc-data-table__cell', 'mdc-data-table__header-cell--numeric', @numberClass(@transaction.balance)) if @includeBalance
    tr.addEventListener 'click', => @showTransactionPopup()
    tr

  nameAndImage: (obj) ->
    return "&nbsp;" unless obj.share_name
    div = element_with_class('div', 'img-name')
    div.append element_with_content('div', @companyImage(obj), 'img')
    div.append element_with_content('div', obj.share_name, 'name')
    div.append element_with_content('div', obj.ticker, 'ticker')
    div

  companyImage: (obj) ->
    return "&nbsp;" unless obj.img
    img = document.createElement('IMG')
    img.setAttribute('src', obj.img)
    img.setAttribute('alt', obj.share_name)
    img

  numberClass: (number) ->
    if number[0] is '-' then 'down' else null

  showTransactionPopup: ->
    if @showMenu()
      @dialog = PopupMessage.showPopup @popupContent(), @editButton(), @deleteButton()
      @dialog.listen 'MDCDialog:closed', (event) =>
        if event.detail.action == 'edit'
          @showEditTransaction()
        else if event.detail.action == 'delete'
          @confirmRemoveTransaction()
    else
      @dialog = PopupMessage.showPopup @popupContent()

  popupContent: -> 
    @content = element_with_class('div', 'dialog-content')
    @content.append PopupMessage.closeIcon()
    @content.append @tableDiv()
    @content

  showMenu: ->
    @canEdit and @transaction.type_class != 'dividend'

  deleteButton: -> PopupMessage.button('Delete', 'delete')
  editButton: -> PopupMessage.button('Edit', 'edit', true)

  confirmRemoveTransaction: ->
    dialog = PopupMessage.confirm("Delete transaction", "Are you sure you want to remove this transaction?")
    dialog.listen 'MDCDialog:closed', (event) =>
      if event.detail.action == 'accept'
        @removeTransaction()
        @dialog.close()

  removeTransaction: ->
    params = params_with_csrf_token()
    params.append '_method', 'DELETE'
    fetch("/portfolios/#{@parent.id}/transactions/#{@transaction.id}", method: 'POST', body: params, headers: {'Content-Type': 'application/x-www-form-urlencoded', 'X-Requested-With': 'XMLHttpRequest'}).then =>
      FlashMessage.success("#{@transaction.share_name} #{@transaction.stype} transaction removed successfully")
      @parent.reload()

  showEditTransaction: ->
    @dialog.close()
    new ModifyTransactionForm(@transaction, @parent)

  tableDiv: -> element_with_content('div', @popupTable(), 'mdc-data-table')
  popupTable: -> element_with_content('table', @popupTableBody(), 'mdc-data-table__table')
  popupTableBody: ->
    table = document.createElement('TBODY')
    table.append @tableRow('Date', @transaction.fdate)
    table.append @tableRow('Type', @transaction.type)
    if @transaction.type_class is 'dividend' or @transaction.type_class is 'purchase' or @transaction.type_class is 'sale'
      table.append @tableRow('Share', @transaction.share_name)
      table.append @tableRow('Ticker', @tickerLink())
      table.append @tableRow('Per share', MoneyFormat.format(@transaction.price, @transactionCurrencyCode(), 6), true)
      table.append @tableRow('No. of shares', @transaction.qty, true)
    if @transaction.type_class is 'purchase' and @transaction.stmp
      table.append @tableRow('Stamp duty', MoneyFormat.formatFull(@transaction.stmp, @transactionCurrencyCode()), true)
    if @transaction.type_class is 'purchase' or @transaction.type_class is 'sale'
      table.append @tableRow('Commission', MoneyFormat.formatFull(@transaction.cmsn, @transactionCurrencyCode()), true)
      table.append @tableRow('Exchange Rate', @transaction.fx) if @transaction.fx and @transaction.fx != "1.0"
      table.append @tableRow('Total', MoneyFormat.formatFull(@transaction.total, @portfolioCurrency.code), true)
    if @transaction.type_class is 'dividend'
      table.append @tableRow('Payment', MoneyFormat.formatFull(@transaction.total, @portfolioCurrency.code), true)
    if @transaction.type_class is 'withdrawal' or @transaction.type_class is 'deposit'
      table.append @tableRow('Amount', MoneyFormat.formatFull(@transaction.total, @portfolioCurrency.code), true)
    table

  transactionCurrencyCode: ->
    if @transaction.curr then @transaction.curr.code else @portfolioCurrency.code

  tableRow: (label, value, number) ->
    tr = element_with_class('tr', 'mdc-data-table__row')
    th = element_with_content('th', label, 'mdc-data-table__header-cell')
    th.setAttribute('scope', 'row')
    tr.append th
    value = "£0" unless value
    value = value.substring(1) if value[0] is '-'
    td = element_with_content('td', value, 'mdc-data-table__cell')
    tr.append td
    tr

  tickerLink: ->
    link = element_with_content('a', @transaction.ticker)
    link.setAttribute('href', @transaction.ln)
    link