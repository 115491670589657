import {MoneyFormat} from 'utils/money_format'

export class PercentFormat

  @format: (float) ->
    "#{MoneyFormat.poundAmount(float)}.#{MoneyFormat.pennyAmount(float)}%"

  @calculatePercentage: (values, index) ->
    total = 0
    total = total + parseFloat(amnt) for amnt in values
    "#{Math.round(values[index] / total * 100)}%"