import {element_with_content, element_with_class} from 'utils/material_elements'

export class OptimizerRow

  constructor: (security, emoji) ->
    @security = security
    @emoji = emoji

  render: ->
    @card = element_with_class('div', 'share', 'mdc-card')
    @card.append @companyImage()
    @card.append @mainBox()
    @card.addEventListener 'click', (e) => 
      @card.classList.toggle('expanded') unless e.target.nodeName == 'A'
    @card

  companyImage: ->
    url = if @security.img then @security.img else 'https://cdn.dividendmax.com/companies/optimizer-default-building.png'
    img = document.createElement('IMG')
    img.setAttribute('alt', @security.name)
    img.setAttribute('src', url)
    element_with_content 'div', img, 'img'

  mainBox: ->
    div = document.createElement('DIV')
    div.classList.add 'main-box'
    div.append @companyName()
    div.append @companyDetails()
    div

  companyName: ->
    div = element_with_class('DIV', 'title-with-icon')
    div.append element_with_content('h2', @security.share, 'mdc-typography--headline5')
    div.append @expandIcon()
    div

  expandIcon: -> element_with_content('div', "&#xe5cf;", 'material-icons', 'mdc-icon-button__icon', 'expand-icon')

  parentWithClass: (div, clazz) ->
    return div if div == null or div.classList.contains(clazz)
    @parentWithClass(div.parentElement, clazz)

  companyDetails: ->
    div = document.createElement('DIV')
    div.classList.add 'details'
    div.append @leftColumn()
    div.append @rightColumn()
    div

  leftColumn: ->
    div = document.createElement('DIV')
    div.classList.add 'col', 'main-col'
    div.append @tickerAndFlag()
    div.append @divsWithLabelAndValue('Sector', @security.sector)
    div.append @divsWithLabelAndValue('Market Cap', @security.market_cap)
    div.append @detailTable()
    div

  tickerAndFlag: ->
    div = document.createElement('DIV')
    div.classList.add 'flag-and-ticker'
    flag = document.createElement('SPAN')
    flag.innerText = @security.flag
    @emoji.replaceInElement(flag)
    div.append flag    
    unless @security.demo
      link = element_with_content('a', @security.code, 'ticker')
      link.setAttribute('href', @security.link)
      div.append link
    div

  divsWithLabelAndValue: (label, value) ->
    div = document.createElement('DIV')
    div.classList.add 'label-val'
    div.append @divWithText("#{label}:")
    div.append @divWithText(value)
    div

  divWithText: (text) ->
    div = document.createElement('DIV')
    div.innerText = text
    div

  rightColumn: ->
    div = document.createElement('DIV')
    div.classList.add 'col'    
    div.append @datesAndYields()
    div.append @dividendsTable()
    div

  datesAndYields: ->
    div = document.createElement('DIV')
    div.classList.add 'dates-and-yield'
    div.append @datesTable()
    div.append @yieldTable()
    div

  yieldTable: ->
    div = document.createElement('DIV')
    div.classList.add 'table'
    div.append @tableValue('Total Yield', @security.tot_yield)
    div.append @tableValue('Annualized', @security.ann_yield, 'highlight')
    div

  datesTable: ->
    div = document.createElement('DIV')
    div.classList.add 'table'
    div.append @tableValue('Buy before', @security.divs[0].ex_date)
    div.append @tableValue('Hold until', @security.divs[@security.divs.length - 1].ex_date)
    div

  detailTable: ->    
    div = document.createElement('DIV')
    div.classList.add 'table'
    div.append @tableValue('Price', @security.price)
    div.append @tableValue('Dividends', "#{@security.total}#{@security.currency} (#{@security.divs.length})")
    div.append @tableValue('CADI', @security.cadi)
    div.append @tableValue('FDI', "#{@security.fdi}%")
    div.append @tableValue('Cover', @security.cover)
    div

  dividendsTable: ->
    title = element_with_content('div', 'Dividends', 'mdc-typography--overline', 'mdc-theme--secondary')
    div = element_with_content('div', title, 'dividends')
    table = document.createElement('TABLE')
    table.append @tHeadRow(['Ex-date', 'Pay date', 'Amount'])
    body = document.createElement('TBODY')
    body.append(@dividendRow(row)) for row in @security.divs
    table.append body
    div.append table
    div

  tHeadRow: (labels) ->
    thead = document.createElement('THEAD')
    tr = document.createElement('TR')
    tr.append(@th(label)) for label in labels
    thead.append(tr)
    thead

  th: (label) ->
    th = document.createElement('TH')
    th.innerText = label
    th

  dividendRow: (row) ->
    tr = document.createElement('TR')
    tr.append @td(row.ex_date)
    tr.append @td(row.pay_date)
    tr.append @dividendAmount(row)
    tr

  td: (content) ->
    td = document.createElement('TD')
    td.innerText = content
    td

  dividendAmount: (row) ->
    content = if row.amount == row.damount then "#{row.amount}#{@security.currency}" else "#{row.amount}#{@security.currency}<span class='dec'>(#{row.damount}#{row.dcur})</span>"
    td = document.createElement('TD')
    td.innerHTML = content
    td

  tableValue: (label, value, extraClass) ->
    div = document.createElement('DIV')
    labelEl = document.createElement('LABEL')
    labelEl.innerText = label
    valueEl = document.createElement('SPAN')
    valueEl.innerText = value
    div.append labelEl
    div.append valueEl
    div.classList.add(extraClass) if extraClass
    div