import {TransactionForm} from 'generator/transaction_form'
import {CompanySearchForm} from 'components/company_search_form'
import {MDCList} from '@material/list'
import {MDCCheckbox} from '@material/checkbox'
import {element_with_class, element_with_content, submit_button} from 'utils/material_elements'
import {form_element, form_params, hidden_field} from 'utils/form_utils'
import {FlashMessage} from 'components/flash'
import {MoneyFormat} from 'utils/money_format'
import {EventTracking} from 'utils/amplitude'
import Choices from 'choices.js'

export class NewTransactionForm extends TransactionForm
  
  constructor: (@id, @parent) ->
    @tab = document.querySelector('#portfolio-data')
    @portfolioCurrency = @parent.portfolioCurrency()
    @reload()

  load: ->
    @emptyTab()
    @grid = element_with_class('div', 'mdc-layout-grid__cell--span-12')
    @grid.append element_with_content('div', 'Add transaction', 'mdc-typography--headline5')
    @appendStep1Content()
    @tab.append @grid

  appendStep1Content: -> 
    div = element_with_class('div', 'inner')
    @grid.append element_with_content('div', div, 'step', 'step1', 'active')
    div.append element_with_content('div', 'Select transaction type', 'mdc-typography--headline6')
    @appendStep1Buttons(div)
    div.append element_with_content('div', @cancelButton(), 'button-bar')

  appendStep1Buttons: (container) ->
    div = element_with_class('ul', 'mdc-list')
    div.setAttribute('role', 'group')
    @appendStep1Option(div, 1, 'Buy a share')
    div.append @listSeparator()
    @appendStep1Option(div, 2, 'Sell a share')
    div.append @listSeparator()
    if @parent.json.close_bal
      @appendStep1Option(div, 3, 'Deposit cash')
      div.append @listSeparator()
      @appendStep1Option(div, 4, 'Withdraw cash')
      div.append @listSeparator()
    container.append(div)
    list = new MDCList(div)
    list.listen 'MDCList:action', (event) => @transactionTypeSelected(event.detail.index + 1)

  appendStep1Option: (div, option, label) ->
    li = element_with_class('li', 'mdc-list-item')
    li.setAttribute('role', 'checkbox')
    li.setAttribute('aria-checked', 'false')
    div.append li
    li.append element_with_class('span', 'mdc-list-item__ripple')
    checkbox = @listCheckbox(option)
    li.append element_with_content('span', checkbox, 'mdc-list-item__graphic')
    l = element_with_content('label', label, 'mdc-list-item__text')
    l.setAttribute('for', "option-#{option}")
    li.append l
    new MDCCheckbox(checkbox)

  listCheckbox: (option) ->
    div = element_with_class('div', 'mdc-checkbox')
    input = element_with_class('input', 'mdc-checkbox__native-control')
    input.setAttribute('type', 'checkbox')
    input.setAttribute('id', "option-#{option}")
    input.setAttribute('name', "type")
    input.setAttribute('value', option)
    div.append input
    bg = element_with_class('div', 'mdc-checkbox__background')

    path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
    path.classList.add 'mdc-checkbox__checkmark-path'
    path.setAttributeNS(null, 'fill', 'none')
    path.setAttributeNS(null, 'd', "M1.73,12.91 8.1,19.28 22.79,4.59")

    svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
    svg.classList.add 'mdc-checkbox__checkmark'
    svg.setAttributeNS null, 'viewBox', '0 0 24 24'
    svg.append path

    bg.append svg
    bg.append element_with_class('div', 'mdc-checkbox__mixedmark')
    div.append bg
    div

  listSeparator: ->
    li = element_with_class('li', 'mdc-list-divider')
    li.setAttribute('role', 'separator')
    li

  transactionTypeSelected: (@type) ->
    @appendStep2Content()
    @prepareStep2()
    clazz = switch @type
      when 1 then 'buy'
      when 2 then 'sell'
      when 3 then 'deposit'
      when 4 then 'withdraw'
    @form.classList.add(clazz)
    document.querySelector('.step1').classList.remove('active')
    document.querySelector('.step2').classList.add('active')    

  appendStep2Content: -> 
    div = element_with_class('div', 'inner')
    @step2 = element_with_content('div', div, 'step', 'step2', 'allow-overflow')
    @grid.append @step2
    div.append element_with_content('div', @formTitle(), 'mdc-typography--headline6')
    div.append @selectShareInput() if @type is 1
    div.append @warningMessage() if @type is 1 or @type is 2
    div.append @newTransactionForm()
    @updateCurrencySymbols()
    @updateTotal()

  prepareStep2: ->
    @search.prepareSearch((value) => @suggestedShareSelected(value)) if @search
    @loadHoldingList() if @type is 2
    @prepareDatePicker()

  warningMessage: ->
    @warning = element_with_class('div', 'transaction-warning-message')
    @warning.append element_with_content('span', "Note: By adding a transaction your holding's value will be overwritten")
    @warning.append element_with_content('em', "You will need to add all sale and purchases for this share to see correct yield")
    @warning

  newTransactionForm: ->
    @form = form_element("/portfolios/#{@id}/transactions", 'POST', 'new_transaction')
    @form.classList.add 'material-form', 'transaction'
    @form.append @selectHoldingInput() if @type is 2
    @form.append @transactionDateInput()
    @form.append @quantityInput() if @type is 1 or @type is 2
    @form.append @priceAndCurrencyInput(@priceInputLabel())
    @form.append @exchangeRateInput() if @type is 1 or @type is 2
    @form.append @commissionInput() if @type is 1 or @type is 2
    @form.append @stampDutyInput() if @type is 1 or @type is 2
    @form.append @totalBox()
    @form.append @formButtons()
    @form.addEventListener 'submit', (e) => @formSubmitted(e)
    @form

  formSubmitted: (e) ->
    e.preventDefault()
    return false unless @validateForm()
    @submitButton.setAttribute('disabled', true)
    params = form_params('#new_transaction')
    params.append('transaction[transaction_type]', @type)
    params.append('transaction[share_id]', @share.id) if @share and @type is 1
    params.append('transaction[holding_id]', @holding.id) if @holding and @type is 2
    params.append('transaction[currency_id]', @selectedCurrencyField('value'))
    fetch("/portfolios/#{@id}/transactions", method: 'POST', body: params, headers: {'Content-Type': 'application/x-www-form-urlencoded', 'X-Requested-With': 'XMLHttpRequest'}).then (response) => @handleFormResponse(response)

  validateForm: ->
    errors = @validateCommonFormFields()
    if @type is 1
      errors.push "A company is required. Use the search box to find the security by name or ticker" unless @share
    if @type is 2
      errors.push "The holding is required. Select from the dropdown" unless @holding

    @showErrors(errors) if errors.length > 0
    errors.length == 0

  handleFormResponse: (response) ->
    response.json().then (json) =>
      if json.success
        FlashMessage.success "Transaction created successfully"
        EventTracking.track('CreateTransaction')
        @parent.reload()
        @scrollToContent()
      else
        @submitButton.removeAttribute('disabled')
        FlashMessage.error "Error creating this transaction"
        @showErrors(json.errors)

  selectShareInput: ->
    @search = new CompanySearchForm(true)
    @search.form()

  selectHoldingInput: ->
    element_with_content('div', @selectHoldingOptions(), 'sell', 'input', 'holding')

  selectHoldingOptions: ->
    @holdingsSelect = @emptySelect 'transaction[holding_id]', 'transaction_holding_id'
    @holdingsSelect.addEventListener 'change', (e) => @holdingSelected(e.detail.value)
    option = element_with_content('option', '- select a holding -')
    option.value = ''
    @holdingsSelect.append option
    @holdingsSelect

  loadHoldingList: ->
    fetch("/portfolios/#{@id}/holdings.json").then (response) => response.json().then (json) =>
      @addHoldings(json.holdings)

  addHoldings: (holdings) ->
    @holdings = {}
    @holdings[holding.id] = holding for holding in holdings

    @choices = new Choices(@holdingsSelect, shouldSort: false)
    options = []
    options.push(@holdingOption(holding)) for holding in holdings
    @choices.setChoices options, 'value', 'label', false
    @holdingsSelect.addEventListener 'showDropdown', => @step2.classList.add 'allow-overflow'
    @holdingsSelect.addEventListener 'hideDropdown', => @step2.classList.remove 'allow-overflow'

  holdingOption: (holding) ->
    value: holding.id,
    label: "<strong>#{holding.ticker}</strong> #{holding.share}"

  holdingSelected: (@holdingId) ->
    @holding = @holdings[@holdingId]
    if @holding
      @setFieldValue document.querySelector('.quantity'), parseInt(@holding.quantity.replace(/,/g, ''))
      @setSelectedOption(@holding)
      @updateTotal()

  setWarningMessageVisible: (shown) ->
    if shown
      @warning.classList.add('shown')
    else
      @warning.classList.remove('shown')

  formTitle: ->
    switch @type
      when 1 then 'Share Purchase'
      when 2 then 'Share Sale'
      when 3 then 'Deposit Funds'
      when 4 then 'Withdraw Funds'

  suggestedShareSelected: (@share) -> @setSelectedOption(@share)

  setSelectedOption: (share_or_holding) ->
    @setWarningMessageVisible(share_or_holding.no_trans)
    if share_or_holding.pr_lo
      @setFieldValue document.querySelector('.price'), MoneyFormat.formatForInput(share_or_holding.pr_lo)
      @selectCurrencyOption(@transactionCurrencyField('id'))
    else
      @setFieldValue document.querySelector('.price'), MoneyFormat.formatForInput(share_or_holding.pr)
      @selectCurrencyOption(share_or_holding.curid)
    @currencySelected()
    if share_or_holding.sd
      document.querySelector('.stamp_duty').classList.remove('hidden')
    else
      document.querySelector('.stamp_duty').classList.add('hidden')