import {MDCTextField} from '@material/textfield'
import {params_with_csrf_token} from './utils/form_utils'
import {StripeCreditCardForm} from './stripe_payment_form'
import {FlashMessage} from './components/flash'

export class MyAccount

  constructor: ->
    new MDCTextField(el) for el in document.querySelectorAll('.mdc-text-field')
    @prepareButtons()
    @prepareStripe()

  prepareButtons: ->
    @addShowButtonListener(a)   for a in document.querySelectorAll('.hide-until-shown button.show')
    @addCancelButtonListener(a) for a in document.querySelectorAll('.hide-until-shown button.cancel-button')
    @prepareDeactivateButton()

  prepareDeactivateButton: ->
    button = document.querySelector('a.deactivate-button')
    button.addEventListener 'click', (e) => @deactivateAccount(e, button.getAttribute('href'))

  deactivateAccount: (e, url) ->
    e.preventDefault()
    fetch(url, method: 'POST', body: params_with_csrf_token(), headers: @headers()).then => @accountDeactivated()

  accountDeactivated: ->
    FlashMessage.success "Account deactivated successfully. You have been logged out"
    setTimeout (-> document.location = "/"), 2000

  headers: ->
    {'Content-Type': 'application/x-www-form-urlencoded', 'X-Requested-With': 'XMLHttpRequest'}

  addShowButtonListener: (button) ->
    button.addEventListener 'click', (e) => @revealFormClicked(e)    

  revealFormClicked: (e) ->
    @modalContainer(e).classList.add 'visible'

  addCancelButtonListener: (button) ->
    button.addEventListener 'click', (e) => @cancelButtonClicked(e)
    
  cancelButtonClicked: (e) ->
    @modalContainer(e).classList.remove 'visible'
    @form.clearForm()

  modalContainer: (event) ->
    event.preventDefault()
    target = event.target
    target = target.parentElement while !target.classList.contains('hide-until-shown')
    target

  prepareStripe: ->
    @form = new StripeCreditCardForm(true, (status) => @creditCardUpdated(status))

  creditCardUpdated: (card) ->
    document.querySelector('#subscription-details .card-type').innerText = @titleize(card.brand)
    document.querySelector('#subscription-details .card-expiry').innerText = @cardExpiry(card)
    document.querySelector('#subscription-details .card-last4').innerText = card.last4
  
  MONTHS = [0, 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  
  cardExpiry: (card) ->
    "#{MONTHS[card.exp_month]} #{card.exp_year}"

  titleize: (brand) ->
    brand[0].toUpperCase() + brand.substring(1,brand.length)

new MyAccount()