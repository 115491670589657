import {MDCSelect} from '@material/select'
import {MDCChipSet} from '@material/chips'
import {EmojiFallback} from 'utils/emoji_fallback'
import {Tooltips} from 'utils/tooltips'

class DeclaredDividendsTable

  constructor: ->
    @selectedIndexIds = []
    @emoji = new EmojiFallback()
    @tooltips = new Tooltips()

    @table = document.querySelector('#content table tbody')
    @loading_icon = document.querySelector('.loading-icon')

    @regionSelect = new MDCSelect(document.querySelector('#region-select'))
    @regionSelect.listen 'MDCSelect:change', => @regionSelected(event.detail.value)

    @indices = new MDCChipSet(document.querySelector('#index-options'))
    @indices.listen 'MDCChip:selection', (event) => @toggleSelectedIndices()

    @regionSelected 1

  regionSelected: (region_id) ->
    @selectedRegion = region_id
    @tooltips.hideAll()
    @setVisibleIndices()
    @showLoadingAnimation()
    @downloadDividendData()

  setVisibleIndices: ->
    @setIndexVisibility(chip) for chip in document.querySelectorAll('#index-options .mdc-chip')
    @setSelectedIndexIds()

  setIndexVisibility: (element) ->
    id = element.getAttribute('id')
    chip = @indices.chips.find (c) -> c.id == id
    chip.selected = element.classList.contains("region-") or element.classList.contains("region-#{@selectedRegion}")
    if chip.selected
      element.classList.add 'visible'
    else
      element.classList.remove 'visible'

  toggleSelectedIndices: () ->
    @tooltips.hideAll()
    @setSelectedIndexIds()
    @toggleRowForIndex(row) for row in document.querySelectorAll('#content table tbody tr')

  setSelectedIndexIds: ->
    @selectedIndexIds = []
    @selectedIndexIds.push(parseInt(document.querySelector("##{id}").dataset.id)) for id in @indices.selectedChipIds

  toggleRowForIndex: (row) ->
    if @isRowIndexSelected(row)
      row.classList.add('visible')
    else
      row.classList.remove('visible')

  isRowIndexSelected: (row) ->
    ids = JSON.parse(row.dataset.indexIds)
    for id in ids
      return true if @selectedIndexIds.includes(id)
    false  

  showLoadingAnimation: ->
    @emptyTable()
    @loading_icon.classList.add 'on'

  emptyTable: ->
    @table.removeChild(@table.firstChild) while @table.firstChild    

  downloadDividendData: ->
    fetch("/dividends/declared.json?region=" + @selectedRegion).then (source) => (source.json()).then (json) => @setTableRows(json)

  setTableRows: (rows) ->
    @table.appendChild(@childRow(row)) for row in rows
    @loading_icon.classList.remove 'on'

  childRow: (json) ->
    tr = document.createElement('TR')
    tr.classList.add('mdc-data-table__row', 'visible')
    tr.dataset.indexIds = json.ind
    tr.appendChild(@linkToCompany(json))
    tr.appendChild(@rowCell(json.ticker))
    cell = @rowCell(json.flag)
    @emoji.replaceInElement(cell)
    tr.appendChild(cell)
    tr.appendChild(@rowCell(json.mic))
    tr.appendChild(@rowCell(json.price))
    tr.appendChild(@rowCell(json.prev))
    tr.appendChild(@amountAndArrow(json))
    tr.appendChild(@rowCell(json.xdd))
    tr

  linkToCompany: (json) ->
    td = document.createElement('TD')
    td.classList.add('mdc-data-table__cell')
    a = document.createElement('A')
    a.setAttribute('href', json.path)
    a.textContent = json.name
    td.appendChild(a)
    td

  amountAndArrow: (json) ->
    td = document.createElement('TD')
    td.classList.add('mdc-data-table__cell')
    td.innerHTML = "<span>#{json.amnt}</span>#{json.icon or ''}"
    td

  rowCell: (text) ->
    td = document.createElement('TD')
    td.classList.add('mdc-data-table__cell')
    td.appendChild(document.createTextNode(text))
    td

new DeclaredDividendsTable()