import {MDCTextField} from '@material/textfield'

export class RequestAddCompanyForm

  constructor: ->
    @prepare() if document.querySelector('body').id is 'request-company'

  prepare: ->
    new MDCTextField(document.querySelector('label.ticker_input'))
    new MDCTextField(document.querySelector('label.name_input'))
    new MDCTextField(document.querySelector('label.country_input'))
    new MDCTextField(document.querySelector('label.note_input'))

new RequestAddCompanyForm()