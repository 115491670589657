import {element_with_class, element_with_content, material_select, material_select_option} from 'utils/material_elements'
import {MDCSelect} from '@material/select'
import {link_button} from 'utils/material_elements'
import {scroll_to_element} from 'utils/scroller'
import {MoneyFormat} from 'utils/money_format'

export class GeneratorTab

  constructor: (@id, @parent) ->
    @tab = document.querySelector('#portfolio-data')
    @reload()

  reload: ->
    @emptyTab()
    @showLoadingAnimation()
    @load()

  emptyTab: ->
    @tab.removeChild(@tab.firstChild) while @tab.firstChild

  scrollToContent: -> scroll_to_element('.tab-tab-tab')

  showLoadingAnimation: ->
    @tab.append element_with_content('div', @loadingAnimation(), 'mdc-layout-grid__cell--span-12', 'loading-cell')

  loadingAnimation: ->
    element_with_content('div', element_with_content('div', '&#xe863;', 'material-icons', 'loading-icon', 'on'), 'loading-icon-wrap')

  setPortfolioValue: (portfolio) ->
    card = document.querySelector(".portfolio-#{portfolio.id}")
    card.querySelector('.mv').innerText = MoneyFormat.format(portfolio.val, portfolio.currency.code)

    value = card.querySelector('.value .mdc-typography--body2')
    icon = card.querySelector('.value .material-icons')
    icon.classList.remove('up', 'down')
    if portfolio.val_p
      icon.classList.add(if portfolio.val_p < 0 then 'down' else 'up')
      icon.innerHTML = if portfolio.val_p < 0 then '&#xe5db;' else '&#xe5d8;'
      value.innerText = "#{portfolio.val_p}%"
    else
      value.innerText = ""

  notLoggedIn: ->
    div = element_with_content('div', element_with_content('div', 'Not logged in', 'mdc-typography--headline5'), 'not-logged-in')
    div.append element_with_content('p', 'You need to be logged in to use the Generator', 'mdc-typography--subtitle1')
    div.append @loginSignupButtons()
    div

  freeAccount: ->
    div = element_with_content('div', element_with_content('div', 'You don\'t have access to the Generator', 'mdc-typography--headline5'), 'not-logged-in')
    div.append element_with_content('p', 'You must activate your free DividendMax trial to access all the tools', 'mdc-typography--subtitle1')
    div.append @activateAccountButton()
    div

  emailNotVerified: ->
    div = element_with_content('div', element_with_content('div', 'Verify your email address', 'mdc-typography--headline5'), 'not-logged-in')
    div.append element_with_content('p', 'You need to click the link in the verification email we sent you to access the Generator', 'mdc-typography--subtitle1')
    div

  accountExpired: ->
    div = element_with_content('div', element_with_content('div', 'Your account has expired', 'mdc-typography--headline5'), 'not-logged-in')
    div.append element_with_content('p', 'You must renew your subscription to continue using DividendMax', 'mdc-typography--subtitle1')
    div.append @paymentButton()
    div

  paymentButton: ->
    element_with_content('div', link_button('Pay for DividendMax', '/payments/new', true, false, false), 'buttons')

  activateAccountButton: ->
    element_with_content('div', link_button('Activate Free Trial', '/begin-free-trial', true, true, false), 'buttons')

  loginSignupButtons: ->
    div = element_with_class('div', 'buttons')
    div.append link_button('Create Account', '/signup', true, false, false)
    div.append link_button('Log In', '/login', false, false, true)
    div

  currencyInput: ->
    @currency_select = material_select('Currency')
    @currency = new MDCSelect(@currency_select)
    @downloadCurrencies (currencies) => @buildCurrencyOptions(currencies)
    element_with_content('div', @currency_select, 'input', 'currency-select-input')

  downloadCurrencies: (callback) ->
    fetch("/currencies.json").then (response) ->
      response.json().then (json) -> callback(json.currencies)

  buildCurrencyOptions: (currencies) ->
    @addCurrencyOption currency, i for currency, i in currencies
    @currency.listen 'MDCSelect:change', => @currencySelected()

  addCurrencyOption: (currency, index) ->
    list = @currency_select.querySelector('ul.mdc-list')
    option = material_select_option("#{currency.name} (#{currency.unit})", currency.id, @isSelectedCurrency(currency))
    option.dataset.unit = currency.unit
    option.dataset.code = currency.code
    list.append option
    @currency.selectedIndex = index if @isSelectedCurrency(currency)

  isSelectedCurrency: (currency) ->
    return @transaction.curr.id == currency.id if @transaction and @transaction.curr
    if @portfolioCurrency then currency.id == @portfolioCurrency.id else currency.code is 'GBP'

  currencySelected: ->
    document.querySelector('.currency-symbol').innerText = @selectedCurrencyField('unit')

  selectCurrencyOption: (id) ->
    for option, i in @currency_select.querySelectorAll('li')
      @currency.selectedIndex = i if option.dataset.value == id.toString()

  selectedCurrencyField: (field) ->
    option = @currency_select.querySelectorAll('li')[@currency.selectedIndex]
    option.dataset[field]

  showErrors: (errors) ->
    div = document.querySelector('#form-errors')
    div.remove() if div
    div = element_with_class('div', 'error-message')
    div.setAttribute('id', 'form-errors')
    div.append element_with_content('div', msg, 'error') for msg in errors
    form = document.querySelector('#portfolio-data form.material-form')
    form.insertBefore(div, document.querySelector('#portfolio-data form.material-form .buttons'))