import OptimizerTable from 'tools/optimizer_table'
import CountdownTable from 'tools/countdown_table'
import WatchlistDividendsTable from 'watchlist_dividends_table'
import {CookieManager} from 'utils/cookie_manager'

export class Dashboard

  constructor: ->
    new OptimizerTable()
    new CountdownTable()
    new WatchlistDividendsTable()
    @addPortfolioClickListener()

  addPortfolioClickListener: ->
    @addListener(portfolio) for portfolio in document.querySelectorAll('#dashboard-portfolios .mdc-card')

  addListener: (portfolio) ->
    portfolio.addEventListener 'click', ->
      CookieManager.set('portfolio', portfolio.dataset.id)
      document.location = '/portfolios'

new Dashboard() if document.body.id == 'dashboard'