import {element_with_class, element_with_content} from 'utils/material_elements'
import {PopupMessage} from 'components/popup_message'
import {MoneyFormat} from 'utils/money_format'

export class FutureDividend
  
  constructor: (@dividend, @currency) ->

  html: ->
    div = element_with_class('li', 'mdc-list-item', 'income')
    div.append @imageBox()
    div.append @middlePart()
    div.append @rightPart()
    div.addEventListener 'click', => @showPopup()
    div

  imageBox: ->
    img = element_with_class('span', 'mdc-list-item__graphic')
    img.append @image() if @dividend.img
    img

  image: ->
    img = document.createElement('IMG')
    img.setAttribute('src', @dividend.img)
    img.setAttribute('alt', @dividend.sn)    
    img

  middlePart: ->
    span = element_with_class('span', 'mdc-list-item__text')
    span.append @name()
    span.append @ticker()
    span.append @date()
    span

  rightPart: ->
    div = element_with_class('div', 'right')
    div.append @amount()
    div.append @shares()
    div

  name: -> element_with_content('span', @dividend.sn, 'mdc-list-item__primary-text', 'name')
  ticker: -> element_with_content('span', @dividend.tk, 'mdc-list-item__primary-text', 'ticker')
  date: -> element_with_content('span', @dividend.pd, 'mdc-list-item__secondary-text')
  amount: -> 
    span = element_with_class('span', 'amount')
    span.append element_with_content('span', 'est', 'estimate') if @dividend.est
    span.append element_with_content('span', MoneyFormat.formatFull(@dividend.to, @currency.code), 'gb')
    span
    
  shares: -> 
    label = if @dividend.qty == "1" then '1 share' else "#{@dividend.qty} shares"
    element_with_content('span', label, 'shares')

  showPopup: ->
    @dialog = PopupMessage.showPopup @popupContent()

  popupContent: -> 
    @content = element_with_class('div', 'dialog-content')
    @content.append PopupMessage.closeIcon()
    @content.append element_with_content('div', @popupTable(), 'mdc-data-table')
    @content


  popupTable: -> element_with_content('table', @popupTableBody(), 'mdc-data-table__table')

  popupTableBody: ->
    table = document.createElement('TBODY')
    table.append @tableRow('Company', @dividend.sn)
    table.append @tableRow('Ticker', @tickerLink())
    table.append @tableRow('Dividend Type', @dividend.ty)
    table.append @tableRow('Ex-Div Date', @dividend.xd)
    table.append @tableRow('Pay Date', @dividend.pd)
    table.append @tableRow('Quantity', @dividend.qty)
    table.append @tableRow('Payment', MoneyFormat.formatFull(@dividend.to, @currency.code))
    table.append @tableRow("#{@dividend.lc} Payment", MoneyFormat.formatFull(@dividend.lt, @dividend.lc)) if @dividend.lt
    table

  tableRow: (label, value) ->
    tr = element_with_class('tr', 'mdc-data-table__row')
    th = element_with_content('th', label, 'mdc-data-table__header-cell')
    th.setAttribute('scope', 'row')
    tr.append th
    value = "£0" unless value
    value = value.substring(1) if value[0] is '-'
    td = element_with_content('td', value, 'mdc-data-table__cell')
    tr.append td
    tr

  tickerLink: ->
    link = element_with_content('a', @dividend.tk)
    link.setAttribute('href', @dividend.ln)
    link