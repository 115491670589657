import {element_with_class, element_with_content} from 'utils/material_elements'

export class MenuBuilder

  constructor: (items...) ->
    @items = items

  build: ->
    element_with_content('div', @ul(), 'mdc-menu', 'mdc-menu-surface')

  ul: ->
    ul = element_with_class('ul', 'mdc-list')
    ul.setAttribute('role', 'menu')
    ul.setAttribute('aria-orientation', 'vertical')
    ul.append @li(item) for item in @items
    ul

  li: (label) ->
    li = element_with_content('li', @span(label), 'mdc-list-item')
    li.setAttribute('role', 'menuitem')
    li

  span: (label) ->
    element_with_content('span', label, 'mdc-list-item__text')