/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require('components/dianomi');
require('components/layout');
require('components/lazy_load_images');
require('articles_search');
require('accuracy');
require('notifications');
require('dashboard');
require('payment');
require('components/lazy_load_trustpilot');
require('generator/generator');
require('begin_free_trial');
require('emoji_fixes');
require('components/banner');
require('components/flash');
require('components/gdpr_cookie_notice');
require('components/setup_form_elements');
require('register');
require('login');
require('password_reset');
if (document.getElementById('request-company') != null) require('request_add_company');
if (document.getElementById('declared-dividends') != null) require('declared_dividends');
if (document.getElementById('company-coverage') != null) require('company_coverage');
if (document.getElementById('new_inquiry') != null) require('inquiries');
if (document.getElementById('my-account') != null) require('my_account');
if (document.getElementById('countdown') != null) require('tools/countdown');
if (document.getElementById('set-password') != null) require('set_password');
if (document.getElementById('new-password') != null) require('new_password');
if (document.querySelector('body#optimizer') != null) require('tools/optimizer');