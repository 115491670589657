import {element_with_class, element_with_content} from 'utils/material_elements'

export class InfiniteScroll

  constructor: (@base_url, @caller, @wholePageScroll) ->
    @container = document.querySelector('.infinite-scroll-container')
    @loading = false
    @finished = false
    @page = 0
    @loadNextPage()
    if @wholePageScroll
      @cutoff = @calculateCutoff()
      document.addEventListener 'scroll', (e) => @documentScrolled(e)
    else
      @container.addEventListener 'scroll', (e) => @documentScrolled(e)

  update: (base_url) ->
    @reset()
    @base_url = base_url
    @loadNextPage()

  reset: ->
    @page = 0
    @finished = false
    @container.removeChild(@container.firstChild) while @container.firstChild    

  calculateCutoff: ->
    if document.body.clientWidth < 769 then 950 else 550

  documentScrolled: (e) ->
    return if @loading or @finished
    if @wholePageScroll
      bottom = document.body.clientHeight - window.innerHeight - window.pageYOffset
      @loadNextPage() if bottom < @cutoff
    else
      bottom = @container.scrollHeight - @container.clientHeight - @container.scrollTop
      @loadNextPage() if bottom < 70

  loadNextPage: ->
    @container.append @loadingAnimation()
    @loading = true
    fetch(@url(), headers: {'X-Requested-With': 'XMLHttpRequest'}).then (response) => @handleResponse(response)

  handleResponse: (data) ->
    data.json().then (json) => @receiveData(json)

  url: ->
    "#{@base_url}#{if @base_url.indexOf('?') > 0 then '&' else '?'}page=#{@page + 1}"

  showLoadingAnimation: ->
    @container.append element_with_content('div', @loadingAnimation())

  loadingAnimation: ->
    el = element_with_content('div', element_with_content('div', '&#xe863;', 'material-icons', 'loading-icon', 'on'), 'loading-icon-wrap')    
    el.setAttribute('id', 'loading')
    el

  receiveData: (json) ->
    @page = parseInt(json.page)
    @finished = json.last
    @caller.dataReceived(json.data)
    @loading = false
    document.getElementById('loading').remove()