import {element_with_class, element_with_content} from 'utils/material_elements'

export class SecurityListItem

  constructor: (data_or_element, @parent) ->
    if data_or_element.dataset
      @security = data_or_element.dataset
      @prepareElement(data_or_element)
    else
      @security = data_or_element
    @id = parseInt(@security.id)
    @editMode = false

  html: ->
    @row = element_with_class('li', 'mdc-list-item')
    @row.dataset.id = @security.id
    @row.dataset.name = @label()
    @row.dataset.image = @security.image
    @row.append @icon()
    @row.append @name()
    @row

  icon: ->
    element_with_content('span', element_with_content('i', '&#xe148;', 'material-icons'), 'mdc-list-item__graphic')

  name: ->
    element_with_content('span', @label(), 'mdc-list-item__text')

  label: ->
    "(#{@security.ticker}) #{@security.name}"

  htmlForSelection: ->
    @row = element_with_class('li', 'mdc-list-item', 'security', "security-#{@security.id}")
    @row.dataset.id = @security.id
    @row.append @companyImage()
    @row.append @companyNameAndQuantity()
    @row.append @companyIconAndInput()
    @row

  companyImage: ->
    graphic = element_with_class('span', 'mdc-list-item__graphic')
    if @security.image
      image = document.createElement('img')
      image.setAttribute 'src', @security.image
      image.setAttribute 'alt', @security.name
      graphic.append image
    graphic

  companyNameAndQuantity: ->
    outer = element_with_class('span', 'mdc-list-item__text')
    outer.append element_with_content('span', @security.name, 'mdc-list-item__primary-text')
    outer.append element_with_content('span', "1 share", 'mdc-list-item__secondary-text')
    outer

  companyIconAndInput: ->
    outer = element_with_class('span', 'mdc-list-item__meta')
    @input = document.createElement('input')
    @input.setAttribute 'name', 'num'
    @input.setAttribute 'max', 999999999
    @input.setAttribute 'min', 1
    @input.setAttribute 'type', 'number'
    @input.setAttribute 'pattern', "[0-9]*"
    @input.setAttribute 'inputmode', "numeric"
    @input.value = 1
    @prepareInput()
    icon = element_with_content('i', '&#xe5d4;', 'material-icons')
    outer.append @input
    outer.append icon
    outer

  prepareElement: (@row) ->
    @input = @row.querySelector('input')
    @prepareInput()

  prepareInput: ->
    @input.addEventListener 'keyup', (e) => 
      if e.keyCode == 13
        e.preventDefault()
        @editComplete()
        return false

    @input.addEventListener 'blur', (e) => 
      @editComplete()

  beginEdit: ->
    @row.classList.add 'edit'
    @editMode = true
    @input.select()
    @input.focus()

  editComplete: ->
    @editModeOff()
    if @parent
      @parent.selectedSecuritiesUpdated()

  editModeOff: ->
    count = parseInt(@input.value)
    count = 1 if isNaN(count) or count < 1
    count = 999999999 if count > 999999999
    @input.value = count
    @input.blur()

    @editMode = false
    @row.classList.remove 'edit'
    text = if count is 1 then "1 share" else "#{count} shares"
    @row.querySelector('.mdc-list-item__secondary-text').innerText = text