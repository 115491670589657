import {MDCTextField} from '@material/textfield'
import {generate_recaptcha_token} from 'utils/form_utils'

export class PasswordResetForm

  constructor: ->
    @recaptchaLoading = false
    @prepare() if document.querySelector('body').id is 'password-reset'

  prepare: ->
    @emailField = @prepareTextField 'email_address'
    @prepareSubmitButton()

  prepareTextField: (id) ->
    el = document.getElementById(id)
    el.addEventListener 'keyup', (e) => @textBoxKeyPress(e)
    new MDCTextField(el.parentElement.parentElement)

  textBoxKeyPress: (e) ->
    disabled = @recaptchaLoading or document.getElementById('email_address').value.length == 0
    document.querySelector('#content form button[type=submit]').disabled = disabled

  prepareSubmitButton: ->
    button = document.querySelector('form#password-reset button.perform-recaptcha')
    button.addEventListener 'click', (e) ->
      e.preventDefault()
      @recaptchaLoading = true
      button.disabled = true
      generate_recaptcha_token -> document.querySelector('form#password-reset').submit()

new PasswordResetForm()