import {MDCNotchedOutline} from '@material/notched-outline';
import {MDCCheckbox} from '@material/checkbox';

try {
  for (let el of document.querySelectorAll('.mdc-notched-outline')) {
    new MDCNotchedOutline(el);
  }  
} catch (e) { }

for (let el of document.querySelectorAll('.mdc-checkbox')) {
  new MDCCheckbox(el);
}