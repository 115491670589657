import {User} from 'utils/user'
import {element_with_class} from 'utils/material_elements'

writeDianomiScript = (contextId) ->
  script = document.createElement('script')
  script.setAttribute('id', "dianomi_context_script")
  script.setAttribute('src', "https://www.dianomi.com/js/contextfeed.js")

  document.getElementById('aph').appendChild script

  div = element_with_class('div', 'dianomi_context')
  div.dataset.dianomiContextId = contextId

  document.getElementById('aph').appendChild div

renderAd = ->
  document.getElementById('aph').classList.add('active')
    
  contextId = if window.innerWidth > 768 then 386 else 289
  writeDianomiScript(contextId)

shouldRenderAd = -> document.getElementById('aph') and !User.isPaidActive()

renderAd() if shouldRenderAd()